import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import ImageUplaod from "../Components/ImageUplaod";
import InputText from "../Components/InputText";
import * as yup from "yup";
import { useFormik } from "formik";
import TextAreaInput from "../Components/TextArea";
import { BlogAPI, ImageAPI } from "../API";
import FloatNotification from "../Components/FloatNotification";
import MDInput from "../Components/MDInput";
import Loading from "../Components/Loading";

const AddBlog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const blogId = new URLSearchParams(location.search).get("id");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      blog_img: "",
      blog_outer_img: "",
      blog_name: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      blog_img: yup.string().required("Blog image must be required"),
      blog_outer_img: yup.string().required("Blog image must be required"),
      blog_name: yup.string().required("Blog name must be required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      location.pathname.includes("add") ? addBlog() : updateBlog();
    },
  });
  const upload = async (name, file) => {
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    setLoading(true);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setLoading(false);
      });
  };
  const addBlog = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${BlogAPI}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };

  const updateBlog = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${BlogAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  const getBlogById = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${BlogAPI}/get-by-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ _id: blogId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  useEffect(() => {
    if (!ignore && blogId) {
      getBlogById();
    }

    return () => {
      ignore = true;
    };
  }, [blogId]);
  return (
    <div className="bg-[#F9FAFB] px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Add Blog
          </h3>
        </div>
        <Button
          text="Back"
          onClick={() => {
            navigate("/super_admin/dashboard/blog-post");
          }}
        />
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex gap-5">
            <div className="w-1/5 h-80">
              <ImageUplaod
                value={values.blog_outer_img ?? ""}
                onChange={(e) => {
                  upload("blog_outer_img", e);
                }}
                label={"Blog Outer Image"}
              />
              {errors.blog_outer_img && touched.blog_outer_img && (
                <p className="text-sm text-red-500">{errors.blog_outer_img}</p>
              )}
            </div>
            <div className="w-4/5 h-80">
              <ImageUplaod
                value={values.blog_img ?? ""}
                onChange={(e) => {
                  upload("blog_img", e);
                }}
                label={"Blog Image"}
              />
              {errors.blog_img && touched.blog_img && (
                <p className="text-sm text-red-500">{errors.blog_img}</p>
              )}
            </div>
          </div>
          <div className="mt-10 flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="blog_name"
                label="Blog Name"
                name="blog_name"
                placeholder="Blog Name"
                value={values?.blog_name ?? ""}
                onChange={handleChange}
                error={errors.blog_name && touched.blog_name}
                errorText={errors.blog_name}
              />
            </div>
            <div>
              <MDInput
                id="description"
                label="Description"
                value={values?.description}
                onChange={(value) => {
                  setFieldValue("description", value);
                }}
                className="mt-2"
                error={errors.description && touched.description}
                errorText={errors.description}
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex mt-5 gap-5 ">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
        >
          Save
        </button>
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="text-center w-full border border-blue-400 gap-2 items-center text-blue-500 font-bold py-[9px] px-4 rounded"
        >
          Cancel
        </button>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default AddBlog;

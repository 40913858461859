import React, { useEffect, useState } from "react";
import ImageUplaod from "../Components/ImageUplaod";
import { AboutPageAPI, HomePageAPI, ImageAPI } from "../API";
import { useFormik } from "formik";
import * as yup from "yup";
import InputText from "../Components/InputText";
import TextAreaInput from "../Components/TextArea";
import FloatNotification from "../Components/FloatNotification";
import Loading from "../Components/Loading";

const AboutPage = () => {
  const [pageId, setPageId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    submitForm,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      home_banner: "",
      title: "",
      tag_line: "",
      description: "",
      explore_world_img: "",
      explore_world_title: "",
      explore_world_tagline: "",
      explore_world_description: "",
      watch_video_img: "",
      watch_video_title: "",
      watch_video_tag_line: "",
      watch_video_description: "",
    },
    validationSchema: yup.object().shape({
      home_banner: yup.string().required("Home Banner must be required"),
      title: yup.string().required("Title must be required"),
      tag_line: yup.string().required("Tag line must be required"),
      description: yup.string().required("Description must be required"),
      watch_video_img: yup
        .string()
        .required("Watch video img must be required"),
      watch_video_title: yup
        .string()
        .required("Watch video title must be required"),
      watch_video_tag_line: yup
        .string()
        .required("Watch video tag_line must be required"),
      watch_video_description: yup
        .string()
        .required("Watch video description must be required"),
      explore_world_img: yup
        .string()
        .required("Explore world img must be required"),
      explore_world_title: yup
        .string()
        .required("Explore world title must be required"),
      explore_world_tagline: yup
        .string()
        .required("Explore world tagline must be required"),
      explore_world_description: yup
        .string()
        .required("explore world description must be required"),
    }),
    onSubmit: () => {
      updateHomePage();
    },
  });
  const upload = async (name, file) => {
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    setLoading(true);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setLoading(false);
      });
  };
  const getHomePage = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(AboutPageAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
            setPageId(data.data._id);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const updateHomePage = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${AboutPageAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          _id: pageId,
          ...values,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            setShowNotification({
              show: true,
              message: <div>Data Updated Success Fully</div>,
              title: "Update",
              status: "success",
            });
            getHomePage();
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!ignore) {
      getHomePage();
    }
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div className="px-4 py-4">
      <div className="lg:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 lg:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            About Hero Section 1
          </h3>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div>
          <div className="py-4">
            {/* <ImageUplaod
              value={values.home_banner ?? ""}
              onChange={(e) => {
                upload("home_banner", e);
              }}
              label={"Home Banner"}
              error={errors?.home_banner && touched?.home_banner ? true : false}
              errorText={errors.home_banner}
            /> */}
            <div className="flex flex-col gap-5">
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="title"
                  label="Title"
                  name="title"
                  placeholder="Title"
                  value={values?.title ?? ""}
                  onChange={handleChange}
                  error={errors.title && touched.title}
                  errorText={errors.title}
                />
                <InputText
                  type="text"
                  id="tag_line"
                  label="Tag Line"
                  name="tag_line"
                  placeholder="Tag Line"
                  value={values?.tag_line ?? ""}
                  onChange={handleChange}
                  error={errors.tag_line && touched.tag_line ? true : false}
                  errorText={errors.tag_line}
                />
              </div>
              <TextAreaInput
                type="text"
                id="description"
                label="Description"
                name="description"
                placeholder="Description"
                value={values?.description ?? ""}
                onChange={handleChange}
                error={errors.description && touched.description}
                errorText={errors.description}
              />
            </div>
          </div>
          <div className="lg:flex items-center justify-between mb-4 gap-3">
            <div className="mb-4 lg:mb-0">
              <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
                Section 2
              </h3>
            </div>
          </div>
          <div className="py-4">
            {/* <ImageUplaod
              value={values.explore_world_img ?? ""}
              onChange={(e) => {
                upload("explore_world_img", e);
              }}
              label={"Explore World Banner"}
              error={
                errors?.explore_world_img && touched?.explore_world_img
                  ? true
                  : false
              }
              errorText={errors.explore_world_img}
            /> */}
            <div className=" flex flex-col gap-5">
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="explore_world_title"
                  label="Explore World Title"
                  name="explore_world_title"
                  placeholder="Explore World Title"
                  value={values?.explore_world_title ?? ""}
                  onChange={handleChange}
                  error={
                    errors.explore_world_title && touched.explore_world_title
                  }
                  errorText={errors.explore_world_title}
                />
                <InputText
                  type="text"
                  id="explore_world_tagline"
                  label="Explore World Tag Line"
                  name="explore_world_tagline"
                  placeholder="Explore World Tag Line"
                  value={values?.explore_world_tagline ?? ""}
                  onChange={handleChange}
                  error={
                    errors.explore_world_tagline &&
                    touched.explore_world_tagline
                  }
                  errorText={errors.explore_world_tagline}
                />
              </div>
              <TextAreaInput
                type="text"
                id="explore_world_description"
                label="Explore World Description"
                name="explore_world_description"
                placeholder="Explore World Description"
                value={values?.explore_world_description ?? ""}
                onChange={handleChange}
                error={
                  errors.explore_world_description &&
                  touched.explore_world_description
                }
                errorText={errors.explore_world_description}
              />
            </div>
          </div>
          <div className="lg:flex items-center justify-between mb-4 gap-3">
            <div className="mb-4 lg:mb-0">
              <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
                Section 3
              </h3>
            </div>
          </div>
          <div className="py-4">
            {/* <ImageUplaod
              value={values.watch_video_img ?? ""}
              onChange={(e) => {
                upload("watch_video_img", e);
              }}
              label={"Watch Video Image"}
              type="video"
              error={
                errors?.watch_video_img && touched?.watch_video_img
                  ? true
                  : false
              }
              errorText={errors.watch_video_img}
            /> */}
            <div className="flex flex-col gap-5">
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="watch_video_title"
                  label="Watch Video Title"
                  name="watch_video_title"
                  placeholder="Watch Video Title"
                  value={values?.watch_video_title ?? ""}
                  onChange={handleChange}
                  error={errors.watch_video_title && touched.watch_video_title}
                  errorText={errors.watch_video_title}
                />
                <InputText
                  type="text"
                  id="watch_video_tag_line"
                  label="Watch Video Tag Line"
                  name="watch_video_tag_line"
                  placeholder="Watch Video Tag Line"
                  value={values?.watch_video_tag_line ?? ""}
                  onChange={handleChange}
                  error={
                    errors.watch_video_tag_line && touched.watch_video_tag_line
                      ? true
                      : false
                  }
                  errorText={errors.watch_video_tag_line}
                />
              </div>
              <TextAreaInput
                type="text"
                id="watch_video_description"
                label="Watch Video Description"
                name="watch_video_description"
                placeholder="Watch Video Description"
                value={values?.watch_video_description ?? ""}
                onChange={handleChange}
                error={
                  errors.watch_video_description &&
                  touched.watch_video_description
                }
                errorText={errors.watch_video_description}
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex mt-5 gap-5 ">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
        >
          Update
        </button>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default AboutPage;

import React, { useEffect, useState } from "react";
import Articles from "../../Components/Articles";
import AboutHero from "../../Components/AboutHero";
import WatchVideo from "../../Components/WatchVideo";
import { BlogAPI, BlogPageAPI } from "../../API";
import { ContextStore } from "../../context/WebContext";
import WebLoading from "../../Components/WebLoading";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BlogBanner from "../../images/blog-img.jpg";
const Blog = () => {

  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-Y599C6263F');

  }, []);
  
  const { setLoading, loading } = ContextStore();
  const [blogPageContents, setBlogPageContents] = useState({});
  const [blogsData, setBlogsData] = useState([]);
  let ignore = false;
  const getBlogPage = async () => {
    setLoading(true);
    await fetch(BlogPageAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setBlogPageContents(data?.data);
        }
      });
  };
  const getBlogs = async () => {
    setLoading(true);
    await fetch(BlogAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setBlogsData(data?.data);
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      getBlogPage();
      getBlogs();
    }

    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <title>
            Blogs - Moj ke Saudagar: Travel Stories, Experiences & Destination
            Secrets
          </title>
          <meta
            name="description"
            content="Read the Moj ke Saudagar Blog for inspiring travel stories, past journey experiences, and tips on exploring new destinations. Discover travel secrets and insights to enhance your next adventure."
          />
          <meta
            name="keywords"
            content="travel blog, journey experiences, travel tips, destination secrets, Moj ke Saudagar, travel stories"
          />
          <meta name="author" content="Moj ke Saudagar" />
          
          <link rel="canonical" href="https://mojkesaudagar.com/blog"></link>
        </Helmet>
      </HelmetProvider>
      {loading ? (
        <WebLoading />
      ) : (
        <>
          <AboutHero pageContent={blogPageContents} imgSrc={BlogBanner} />
          <div className="mt-10 md:mt-20">
            <Articles blogsData={blogsData} />
          </div>
          <WatchVideo />
        </>
      )}
    </div>
  );
};

export default Blog;

import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Heading from "./Heading";
import { ContextStore } from "../context/WebContext";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyImage from "./LazyImage";
const TravelGallery = () => {
  const { dreamPlace } = ContextStore();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 7000,
  };
  const firstImg = dreamPlace?.filter((place) => place?.index === "1");
  const secondImg = dreamPlace?.filter((place) => place?.index === "2");
  const thirdImg = dreamPlace?.filter((place) => place?.index === "3");
  const fourthImg = dreamPlace?.filter((place) => place?.index === "4");
  const fifthImg = dreamPlace?.filter((place) => place?.index === "5");
  const sixthImg = dreamPlace?.filter((place) => place?.index === "6");
  return (
    <div className="mx-10 md:mx-16 mt-10">
      <div className="flex gap-4 items-center justify-center">
        <Heading heading={"travel gallery"} />
      </div>
      <div className="capitalize text-center text-4xl text-[#1A093F] mt-2 font-bold w-full">
        your dream places
      </div>
      <div className="py-10">
        <div className="lg:h-[700px] overflow-y-scroll grid-layout ">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter={"20px"}>
              <Slider {...settings}>
                {firstImg?.map((place, index) => (
                  <LazyImage
                    alt={index + 1}
                    key={index}
                    src={place?.img}
                    className="w-[400px] h-[200px] lg:h-[400px] lg:w-[400px] 2xl:w-[800px] object-cover rounded-xl"
                  />
                ))}
              </Slider>
              <Slider {...settings}>
                {secondImg.map((place, index) => (
                  <LazyImage
                    alt={index + 1}
                    key={index}
                    className="w-[400px] h-[200px] lg:h-[200px] lg:w-[500px] 2xl:w-[800px] object-cover rounded-xl"
                    src={place?.img}
                  />
                ))}
              </Slider>
              <Slider {...settings}>
                {thirdImg?.map((place, index) => (
                  <LazyImage
                    alt={index + 1}
                    key={index}
                    src={place?.img}
                    className="w-[400px] h-[200px] lg:h-[400px] lg:w-[400px] 2xl:w-[800px] object-cover rounded-xl"
                  />
                ))}
              </Slider>
              <Slider {...settings}>
                {fourthImg?.map((place, index) => (
                  <LazyImage
                    alt={index + 1}
                    key={index}
                    src={place?.img}
                    className="w-[400px] h-[200px] lg:h-[250px] lg:w-[400px] 2xl:w-[800px] object-cover rounded-xl"
                  />
                ))}
              </Slider>
              <Slider {...settings}>
                {fifthImg?.map((place, index) => (
                  <LazyImage
                    alt={index + 1}
                    key={index}
                    src={place?.img}
                    className="w-[400px] h-[200px] lg:h-[450px] lg:w-[400px] 2xl:w-[800px] hidden lg:block object-cover rounded-xl"
                  />
                ))}
              </Slider>
              <Slider {...settings}>
                {sixthImg?.map((place, index) => (
                  <LazyImage
                    alt={index + 1}
                    key={index}
                    src={place?.img}
                    className="w-[400px] h-[200px] lg:h-[250px] lg:w-[400px] 2xl:w-[800px] object-cover rounded-xl"
                  />
                ))}
              </Slider>
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </div>
  );
};

export default TravelGallery;

import React, { useState } from "react";
import logo from "../images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const headerLink = [
    { name: "Home", link: "/", active_name: "" },
    { name: "About Us", link: "/about", active_name: "about" },
    { name: "Tour List", link: "/tour-list", active_name: "tour-list" },
    { name: "Blogs", link: "/blog", active_name: "blog" },
    { name: "Contact Us", link: "/contact-us", active_name: "contact-us" },
  ];
  const handleShowSidebar = () => {
    setShowSidebar(true);
  };
  const handleHideSidebar = () => {
    setShowSidebar(false);
  };
  return (
    <div>
      <nav className="bg-white shadow-[3px_3px_5px rgba(0, 0, 0, 0.1)]">
        <div
          className={`sidebar z-50 ease-in-out duration-500 flex gap-10 fixed top-0 py-5 left-0 h-screen w-[250px] bg-[#FEF4E4]/50 backdrop-blur-sm shadow-xl shadow-gray-500 flex-col items-start justify-start ${
            showSidebar
              ? "translate-x-0 opacity-100"
              : "-translate-x-72 opacity-0"
          }`}
        >
          <div
            onClick={handleHideSidebar}
            className="w-full flex justify-end px-4"
          >
            <XMarkIcon className="h-6 w-6 cursor-pointer text-[#FD6469]" />
          </div>
          <div className="flex flex-col gap-5 px-5">
            {headerLink.map((item, index) => (
              <div key={index}>
                <div
                  onClick={() => {
                    navigate(item.link);
                    setShowSidebar(false);
                  }}
                  className={`h-full font-jost ${
                    location.pathname.split("/")[1] === item.active_name
                      ? "text-[#FD6469]"
                      : "text-gray-400"
                  } cursor-pointer font-semibold hover:text-[#FD6469]`}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full list-none flex items-center justify-between px-5 md:px-10 lg:px-20 py-5">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={logo} className="h-20 w-20 md:h-24 md:w-24" />
          </div>
          <div className="flex gap-8">
            {headerLink.map((item, index) => (
              <div key={index} className="hidden md:block">
                <div
                  onClick={() => navigate(item.link)}
                  className={`h-full font-jost ${
                    location.pathname.split("/")[1] === item.active_name
                      ? "text-[#FD6469]"
                      : "text-gray-400"
                  } cursor-pointer font-semibold hover:text-[#FD6469]`}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
          <div
            className="block md:hidden cursor-pointer"
            onClick={handleShowSidebar}
          >
            <Bars3Icon className="h-8 w-8 flex items-center text-black" />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

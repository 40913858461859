import React, { createContext, useContext, useEffect, useState } from "react";
import { AboutPageAPI, DreamPLaceAPI } from "../API";
export const queryForm = {
  full_name: "",
  email: "",
  phone_number: "",
  description: "",
  destination_from: "",
  destination_to: "",
  check_in: "",
  check_out: "",
  room: "",
  adult: "",
  children: "",
  travel_from: "",
  travel_to: "",
  infants: "",
  train_class: "",
  pickup_time: "",
  drop_time: "",
  destination: "",
  visa_date: "",
};
const Context = createContext({
  bookType: "information",
  setBookType: () => {},
  tabType: "Hotel",
  setTabType: () => {},
  dreamPlace: [],
  formDetails: queryForm,
  setFormDetails: () => {},
  loading: false,
  setLoading: () => {},
});

const WebContext = ({ children }) => {
  const [bookType, setBookType] = useState("information");
  const [tabType, setTabType] = useState("Hotel");
  const [dreamPlace, setDreamPlace] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    description: "",
    destination_from: "",
    destination_to: "",
    check_in: new Date().toISOString(),
    check_out: new Date().toISOString(),
    room: "",
    adult: "",
    children: "",
    travel_from: "",
    travel_to: "",
    infants: "",
    train_class: "",
    pickup_time: "",
    drop_time: "",
    destination: "",
    visa_date: "",
  });
  let ignore = false;

  const getAllDreamPlace = async () => {
    setLoading(true);
    await fetch(DreamPLaceAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ limit: 99999 }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setDreamPlace(data.data);
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      getAllDreamPlace();
    }

    return () => {
      ignore = true;
    };
  }, []);
  return (
    <Context.Provider
      value={{
        bookType,
        setBookType,
        tabType,
        setTabType,
        dreamPlace,
        setFormDetails,
        formDetails,
        setLoading,
        loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default WebContext;

export const ContextStore = () => {
  return useContext(Context);
};

import { Fragment, useState } from "react";
import Select from "react-select";

export default function Selection({
  label,
  name,
  option,
  value,
  onChange,
  placeholder,
}) {
  const [query, setQuery] = useState("");

  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="block text-gray-800 font-bold">
          {label}
        </label>
      )}
      <Select
        value={value}
        options={option}
        placeholder={placeholder}
        className="w-full border border-gray-300 focus:border-blue-600 px-3 rounded mt-2 outline-none focus:ring-blue-600 :ring-blue-600"
        onChange={(e) => {
          onChange(e);
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            padding: "0px",
            border: "none",
            boxShadow: "0px 0px 0px 0px",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            padding: "0px",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            paddingLeft: "8px",
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            width: 0,
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            fill: "#1A093F",
          }),
          container: (baseStyle) => ({
            ...baseStyle,
            padding: "0px",
          }),
          menuList: (baseStyle) => ({
            ...baseStyle,
            height: "150px",
          }),
        }}
      />
    </div>
  );
}

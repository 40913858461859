import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import ImageUplaod from "../Components/ImageUplaod";
import InputText from "../Components/InputText";
import * as yup from "yup";
import { useFormik } from "formik";
import TextAreaInput from "../Components/TextArea";
import { TestimonialAPI, ImageAPI } from "../API";
import FloatNotification from "../Components/FloatNotification";
import Loading from "../Components/Loading";

const AddTestimonial = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const testimoniId = new URLSearchParams(location.search).get("id");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      user_img: "",
      user_name: "",
      description: "",
      review: 1,
    },
    validationSchema: yup.object().shape({
      user_img: yup.string().required("User image is required"),
      user_name: yup.string().required("User name is required"),
      description: yup.string().required("Description is required"),
      review: yup
        .number()
        .min(1, "please enter minimum 0")
        .max(5, "you can add maximum 5")
        .required("review is required"),
    }),
    onSubmit: () => {
      location.pathname.includes("add")
        ? addTestimonial()
        : updateTestimonial();
    },
  });
  const upload = async (name, file) => {
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    setLoading(true);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setLoading(false);
      });
  };
  const addTestimonial = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${TestimonialAPI}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };

  const updateTestimonial = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${TestimonialAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  const getTestimonialById = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${TestimonialAPI}/get-by-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ _id: testimoniId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  useEffect(() => {
    if (!ignore && testimoniId) {
      getTestimonialById();
    }

    return () => {
      ignore = true;
    };
  }, [testimoniId]);
  return (
    <div className="bg-[#F9FAFB] px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Add Testimonial
          </h3>
        </div>
        <Button
          text="Back"
          onClick={() => {
            navigate("/super_admin/dashboard/testimonial");
          }}
        />
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div>
          <div className="mt-5">
            <ImageUplaod
              value={values.user_img ?? ""}
              onChange={(e) => {
                upload("user_img", e);
              }}
              label={"User Image"}
            />
            {errors.user_img && touched.user_img && (
              <p className="text-sm text-red-500">{errors.user_img}</p>
            )}
          </div>
          <div className="mt-10 flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="user_name"
                label="User Name"
                name="user_name"
                placeholder="User Name"
                value={values?.user_name ?? ""}
                onChange={handleChange}
                error={errors.user_name && touched.user_name}
                errorText={errors.user_name}
              />
              <InputText
                type="number"
                id="review"
                label="Review"
                name="review"
                placeholder="Review"
                value={values?.review ?? ""}
                onChange={handleChange}
                error={errors.review && touched.review}
                errorText={errors.review}
              />
            </div>
            <TextAreaInput
              type="text"
              id="description"
              label="Description"
              name="description"
              placeholder="Description"
              value={values?.description ?? ""}
              onChange={handleChange}
              error={errors.description && touched.description}
              errorText={errors.description}
            />
          </div>
        </div>
      )}
      <div className="flex mt-5 gap-5 ">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
        >
          Save
        </button>
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="text-center w-full border border-blue-400 gap-2 items-center text-blue-500 font-bold py-[9px] px-4 rounded"
        >
          Cancel
        </button>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default AddTestimonial;

import { PhotoIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const ImageUplaod = ({ label, value, onChange, error, errorText, type }) => {
  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      onChange(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div>
      <div className="mb-4">{label}</div>

      <div {...getRootProps({ className: "dropzone" })}>
        {value ? (
          type === "video" ? (
            <video
              src={value}
              className="w-full h-80"
              autoPlay
              control="false"
            ></video>
          ) : (
            <img src={value} alt="img" className={`w-full h-80 object-cover`} />
          )
        ) : (
          <>
            <input className="input-zone" {...getInputProps()} />
            <div
              className="relative border-2 border-gray-300 border-dashed rounded-lg p-6"
              id="dropzone"
            >
              <div
                className={`text-center ${
                  isDragActive ? "opacity-10" : "backdrop-opacity-100"
                }`}
              >
                <div className="flex justify-center">
                  <PhotoIcon className="h-16 w-16" />
                </div>

                <h3 className="mt-2 text-sm font-medium text-gray-900 relative cursor-pointer">
                  <span>Drag and drop</span>
                  <span className="text-indigo-600"> or browse</span>
                  <span>to upload</span>
                </h3>
                <p className="mt-1 text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {error && <p className="text-red-500 text-sm">{errorText}</p>}
    </div>
  );
};

export default ImageUplaod;

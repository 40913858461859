import React, { useState } from "react";
import Select from "react-select";

const HeroSelection = ({
  label,
  Icon,
  option,
  value,
  onChange,
  displayValue,
}) => {
  return (
    <div className="flex items-center gap-3">
      <Icon className="h-6 w-6 text-[#FD6469]" />
      <div className="w-44">
        <label className="capitalize block text-sm text-gray-500">
          {label}
        </label>
        <Select
          className=""
          options={option}
          onChange={(e) => {
            onChange(e);
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              padding: 0,
              border: "none",
              minHeight: "30px",
              height: "30px",
              boxShadow: "0px 0px 0px 0px",
              backgroundColor: "#fef2f2",
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: "#1A093F",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: "0px",
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              width: 0,
            }),
            indicatorsContainer: (baseStyles) => ({
              fill: "#1A093F",
            }),
            container: (baseStyle) => ({
              ...baseStyle,
              padding: "0px",
              borderBottom: "1px solid #ef4444",
              borderRadius: "0px",
              paddingBottom: "4px",
            }),
            menuList: (baseStyle) => ({
              ...baseStyle,
              height: "150px",
            }),
          }}
        />
      </div>
    </div>
  );
};

export default HeroSelection;

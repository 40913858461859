import React from "react";

const PrefixInput = ({
  Icon,
  name,
  value,
  onChange,
  error,
  errorText,
  placeholder,
  id,
}) => {
  return (
    <div>
      <div className="relative">
        <input
          id={id}
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          className="pl-10 pr-4 py-2 rounded-lg shadow-[0_0_4px_1px_#FD6469] w-full xl:w-auto"
          placeholder={placeholder}
        />
        <div
          className="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
        >
          <Icon className="h-5 w-5 text-[#FD6469]/60" />
        </div>
        {error && <p className="text-red-500 text-sm">{errorText}</p>}
      </div>
    </div>
  );
};

export default PrefixInput;

export const PrefixTextInput = ({
  Icon,
  name,
  value,
  onChange,
  error,
  errorText,
  placeholder,
  id,
}) => {
  return (
    <div className="w-full">
      <textarea
        id={id}
        name={name}
        value={value}
        rows={5}
        onChange={onChange}
        className="pl-4 pr-4 py-2 rounded-lg shadow-[0_0_4px_1px_#FD6469] w-full"
        placeholder={placeholder}
      />
      {error && <p className="text-red-500 text-sm">{errorText}</p>}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import AboutHero from "../../Components/AboutHero";
import Contact from "../../Components/Contact";
import TravelGallery from "../../Components/TravelGallery";
import Heading from "../../Components/Heading";
import contactUsImg from "../../images/contactus.png";
import InputText from "../../Components/InputText";
import TextAreaInput from "../../Components/TextArea";
import { IconMailFilled } from "@tabler/icons-react";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { ContactUsAPI, LeadAPI } from "../../API";
import { useFormik } from "formik";
import * as yup from "yup";
import FloatNotification from "../../Components/FloatNotification";
import { ContextStore } from "../../context/WebContext";
import WebLoading from "../../Components/WebLoading";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LazyImage from "../../Components/LazyImage";
import ContactUsBanner from "../../images/contact-us.jpg";
const ContactUs = () => {

  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-Y599C6263F');

  }, []);
  
  const phoneRegExp = /^(\+?91|0)?[6789]\d{9}$/;
  const [contactPageContents, setContactPageContents] = useState({});
  const { setLoading, loading, formDetails } = ContextStore();
  let ignore = false;
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const ContactData = [
    {
      name: "email us",
      value: ["mojkesaudagar@gmail.com"],
      icon: IconMailFilled,
    },
    {
      name: "call us",
      value: ["Surat - +91 88667 71988", "Rajkot - +91 95124 01110"],
      icon: PhoneIcon,
    },
    {
      name: "our location",
      value: [
        "210, Silver Business Hub, Bapa Sitaram Chowk, Puna Simada Road, Surat - 395003",
        "1102, North Block, Twin Star, Near Balaji Hall, 150 Feet Ring Road, Rajkot",
      ],
      icon: MapPinIcon,
    },
  ];
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("Full name is required"),
      email: yup
        .string()
        .email("Please enter valid email")
        .required("Email name must be required"),
      phone_number: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone Numbwe is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      addLead();
    },
  });

  const addLead = () => {
    setLoading(true);
    fetch(`${LeadAPI}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formDetails,
        full_name: values.full_name,
        phone_number: values.phone_number,
        email: values.email,
        description: values.description,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          resetForm();
          setShowNotification({
            show: true,
            message: (
              <div>
                Your message has been received, and we'll get back to you as
                soon as possible. Have a great day!
              </div>
            ),
            title: "Add",
            status: "success",
          });
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
      });
  };
  const getContactPage = async () => {
    setLoading(true);
    await fetch(ContactUsAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setContactPageContents(data?.data);
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      getContactPage();
    }

    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <title>
            Contact Us - Moj ke Saudagar: Reach Out for Travel Inquiries &
            Support
          </title>
          <meta
            name="description"
            content="Get in touch with Moj ke Saudagar for any inquiries or support. Visit our Contact page to reach out to our team for personalized assistance with your travel needs."
          />
          <meta
            name="keywords"
            content="contact us, travel inquiries, support, Moj ke Saudagar, travel assistance, customer service"
          />
          <meta name="author" content="Moj ke Saudagar" />
          
          <link
            rel="canonical"
            href="https://mojkesaudagar.com/contact-us"
          ></link>
        </Helmet>
      </HelmetProvider>
      {loading ? (
        <WebLoading />
      ) : (
        <>
          <AboutHero
            pageContent={contactPageContents}
            imgSrc={ContactUsBanner}
          />
          {/* <AboutHero
            pageContent={contactPageContents}
            imgSrc={contactPageContents?.["contact_banner"]}
          /> */}
          <div className="mt-10 mb-10 md:mt-20 md:mb-20 2xl:mb-14 2xl:mt-14 flex justify-center w-full">
            <div className="flex w-full justify-center h-full items-center">
              <div>
                <div className="flex gap-4 items-center justify-center">
                  <Heading heading={"contact us"} />
                </div>
                <div className="capitalize text-center text-4xl text-[#1A093F] mt-5 font-bold w-full">
                  get in touch
                </div>
                <div className="flex flex-col lg:flex-row gap-20 w-full justify-between mt-12 px-10 md:px-0">
                  <div className="flex lg:block justify-center ">
                    <LazyImage
                      className="object-cover object-center"
                      alt="hero"
                      src={contactUsImg}
                    />
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="bg-white rounded-[30px] shadow-[0_0_20px_2px_rgba(0,0,0,0.3)] space-y-5 md:space-y-10 py-8 px-5 ">
                      <div>
                        <InputText
                          type="text"
                          id="full_name"
                          label="Full Name"
                          name="full_name"
                          placeholder="Title"
                          value={values?.full_name ?? ""}
                          onChange={handleChange}
                          error={errors.full_name && touched.full_name}
                          errorText={errors.full_name}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row gap-5">
                        <InputText
                          type="text"
                          id="email"
                          label="Email"
                          name="email"
                          placeholder="Email"
                          value={values?.email ?? ""}
                          onChange={handleChange}
                          error={errors.email && touched.email}
                          errorText={errors.email}
                        />
                        <InputText
                          type="text"
                          id="phone_number"
                          label="Phone Number"
                          name="phone_number"
                          placeholder="Phone Number"
                          value={values?.phone_number ?? ""}
                          onChange={handleChange}
                          error={errors.phone_number && touched.phone_number}
                          errorText={errors.phone_number}
                        />
                      </div>
                      <div>
                        <TextAreaInput
                          type="text"
                          id="description"
                          label="Description"
                          name="description"
                          placeholder="Description"
                          value={values?.description ?? ""}
                          onChange={handleChange}
                          error={errors.description && touched.description}
                          errorText={errors.description}
                        />
                      </div>
                      <div className="w-full flex justify-center">
                        <button
                          type="submit"
                          className="bg-[#FD5056] shadow-lg shadow-[#FD5056] hover:text-[#FD5056] transition-all duration-300 hover:bg-white text-white rounded-full px-10 py-3 font-medium font-rubik"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex gap-4 items-center justify-center">
              <Heading heading={"details"} />
            </div>
            <div className="capitalize text-center text-4xl text-[#1A093F] mt-5 font-bold w-full">
              contact details
            </div>

            <div>
              <section className="text-gray-600 body-font relative">
                <div className="px-5 mt-12 lg:w-5/6 2xl:w-4/5 gap-10 mx-auto flex justify-between flex-col xl:flex-row">
                  <div className="w-full h-[400px] xl:h-auto">
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      className="rounded-xl"
                      title="map"
                      marginHeight="0"
                      marginWidth="0"
                      scrolling="no"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59508.99140004623!2d72.85452354235413!3d21.219474515579694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f91b4cf4a0b%3A0xaee058b43508e225!2sMoj%20ke%20Saudagar!5e0!3m2!1sen!2sin!4v1719745875168!5m2!1sen!2sin"
                    ></iframe>
                  </div>
                  <div className="w-full">
                    {ContactData.map((item, index) => (
                      <div key={index} className="p-2 w-full ">
                        <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg shadow-[0_0_5px_2px_rgba(0,0,0,0.2)]">
                          <div className="bg-[#FD6469] w-12 h-12 flex justify-center items-center rounded-md mr-4">
                            <item.icon className="h-6 w-6 text-white" />
                          </div>
                          <div className="flex-grow">
                            <h2 className="capitalize text-gray-900 title-font font-medium">
                              {item.name}
                            </h2>
                            <div className="text-gray-500 w-56 md:w-80 flex flex-col gap-2">
                              {item.value.map((contact, ind) => (
                                <p key={ind}>{contact}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-[400px] xl:h-auto">
                    <iframe
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      className="rounded-xl"
                      title="map"
                      marginHeight="0"
                      marginWidth="0"
                      scrolling="no"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.7578709276562!2d70.77936663250073!3d22.273218888891947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca46f5c40c5f%3A0xeb865115b5c3753a!2sTWIN%20STAR!5e0!3m2!1sen!2sin!4v1720813007218!5m2!1sen!2sin"
                    ></iframe>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="mt-10 md:mt-20">
            <Contact bgImg="bg-[url(/public/about-contact.png)]" />
          </div>
          <div className="mt-10 md:mt-20">
            <TravelGallery />
          </div>
        </>
      )}

      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default ContactUs;

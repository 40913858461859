import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { ContextStore } from "../context/WebContext";

const WebLayout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default WebLayout;

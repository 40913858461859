import React from "react";
import PackageHero from "../../Components/PackageHero";
import Booking from "../../Components/Booking";
import MDInput from "../../Components/MDInput";

const TourPlan = ({ content }) => {
  const planData = content?.tourplan?.map((tour, ind) => {
    return {
      day: `day ${ind + 1}`,
      name: tour?.plan_name,
      description: tour?.description,
    };
  });
  return (
    <div>
      <PackageHero
        name={content?.information?.destination_name}
        banner={content?.banner_img}
      >
        <div className="my-10 mx-5 md:mx-16 flex flex-col xl:flex-row gap-10">
          <div className="w-full">
            <h1 className="text-5xl font-semibold font-poppins capitalize">
              tour plan
            </h1>
            <div className="py-16">
              {planData.map((item, index) => (
                <div key={index} className="w-full">
                  <div className="flex relative pb-12">
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                      <div className="h-full w-1 border-r-2 mr-0.5 border-dashed border-[#FD6469] pointer-events-none"></div>
                    </div>
                    <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#FD6469] inline-flex items-center justify-center text-white relative z-10">
                      {index + 1}
                    </div>
                    <div className="flex-grow pl-4 space-y-10">
                      <h2 className="title-font text-gray-900 tracking-wider capitalize font-poppins text-xl font-bold">
                        {item.day} : {item.name}
                      </h2>
                      <div className="leading-relaxed text-gray-500 capitalize text-justify">
                        <MDInput value={item?.description} viewOnly />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="leading-relaxed text-gray-500 capitalize text-justify">
              <div className="text-black text-lg font-semibold">Inclusion</div>
              <MDInput
                value={content?.include ? content?.include : "--"}
                viewOnly
              />
            </div>
            <div className="leading-relaxed text-gray-500 capitalize text-justify mt-8">
              <div className="text-black text-lg font-semibold">Exclution</div>
              <MDInput
                value={content?.exclude ? content?.exclude : "--"}
                viewOnly
              />
            </div>
          </div>
          <div>
            <Booking destination={content?.information?.destination_name} />
          </div>
        </div>
      </PackageHero>
    </div>
  );
};

export default TourPlan;

import React from "react";
import PackageHero from "../../Components/PackageHero";
import Booking from "../../Components/Booking";
import MDInput from "../../Components/MDInput";
import LazyImage from "../../Components/LazyImage";

const Reviews = ({ content }) => {
  return (
    <div>
      <PackageHero
        name={content?.information?.destination_name}
        banner={content?.banner_img}
      >
        <div className="my-10 mx-5 md:mx-16 flex flex-col xl:flex-row gap-10">
          <div className="w-full">
            <h1 className="text-5xl font-semibold font-poppins capitalize">
              reviews
            </h1>
            <div className="py-16">
              {content?.review?.map((review, ind) => (
                <div key={ind} className="inline-flex w-full items-center mb-8">
                  <LazyImage
                    alt="blog"
                    src={review?.review_img}
                    className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
                  />
                  <span className="flex-grow w-full flex flex-col pl-4">
                    <span className="font-poppins flex justify-between items-center">
                      <span className="font-bold text-gray-900 capitalize">
                        {review?.review_name}
                      </span>
                      <span className="capitalize text-[#FD6469] text-xs">
                        {new Date(review?.createdAt).toDateString()}
                      </span>
                    </span>
                    <span className="text-gray-900 text-xs mt-0.5 capitalize font-poppins text-justify">
                      <MDInput value={review?.review_description} viewOnly />
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Booking destination={content?.information?.destination_name} />
          </div>
        </div>
      </PackageHero>
    </div>
  );
};

export default Reviews;

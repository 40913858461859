import React from "react";
import experienceImg from "../images/experience.png";
import planeImg from "../images/plane.png";
import Heading from "./Heading";
import LazyImage from "./LazyImage";
const Experience = ({ content }) => {
  return (
    <div className="mx-10 md:mx-16 mt-20">
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex xl:px-5 xl:flex-row flex-col items-center gap-12 xl:gap-0">
          <div className="mb-10 md:mb-0 w-full md:w-4/5 xl:w-full">
            <LazyImage
              className="object-cover object-center"
              alt="hero"
              width="600px"
              height="600px"
              src={experienceImg}
            />
          </div>
          <div className="w-full md:w-4/5 xl:w-full xl:pl-20 flex flex-col xl:items-start xl:text-left items-center text-center gap-5 md:gap-8">
            <div className="flex justify-center md:justify-start gap-4 items-center">
              <Heading heading={content?.experience_title} />
            </div>
            <div className="w-full flex justify-center xl:justify-start">
              <h1 className="font-poppins text-2xl md:text-4xl xl:text-[48px] !leading-tight font-bold text-[#1A093F] capitalize 2xl:text-5xl">
                {content?.experience_tagline}
              </h1>
            </div>
            <p className="mb-8 leading-relaxed xl:text-lg 2xl:text-xl text-justify">
              {content?.description}
            </p>
            <div className="flex items-center gap-5 md:gap-14">
              <div>
                <p className="text-4xl font-bold font-rubik text-[#FD6469]">
                  {content?.experience_count}
                </p>
                <p className="capitalize font-rubik font-semibold text-left text-black text-sm">
                  years
                  <br /> experience
                </p>
              </div>
              <div className="h-14 border border-gray-300"></div>
              <div>
                <p className="text-4xl font-bold font-rubik text-[#FD6469]">
                  {content?.destination_collobration_count}
                </p>
                <p className="capitalize font-rubik font-semibold text-left text-black text-sm">
                  destination
                  <br /> collaboration
                </p>
              </div>
              <div className="h-14 border border-gray-300"></div>
              <div>
                <p className="text-4xl font-bold font-rubik text-[#FD6469]">
                  {content?.happy_customer_count}
                </p>
                <p className="capitalize font-rubik font-semibold text-left text-black text-sm">
                  happy
                  <br /> customer
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Experience;

import React from "react";

const Button = ({ Icon, text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center gap-2 border rounded-md px-5 py-2 text-blue-500 border-blue-500 hover:bg-blue-500 font-semibold hover:text-white cursor-pointer transition-all duration-200"
    >
      {Icon && <Icon className="h-5 w-5" />} {text}
    </div>
  );
};

export default Button;

import React from "react";
import loading from "../images/loading.gif";
const WebLoading = ({ width }) => {
  return (
    <div
      className={`${
        width ? width : "w-full h-screen bg-white"
      } flex justify-center items-center`}
    >
      <img src={loading} className={` ${width ? width : "h-60 w-60"}`} />
    </div>
  );
};

export default WebLoading;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import InputText from "../Components/InputText";
import * as yup from "yup";
import { useFormik } from "formik";
import TextAreaInput from "../Components/TextArea";
import { LeadAPI } from "../API";
import FloatNotification from "../Components/FloatNotification";
import Selection from "../Components/Selection";
import DatePicker from "../Components/DatePicker";
import Loading from "../Components/Loading";
const queryOption = [
  { label: "Hotel", value: "hotel" },
  { label: "Flight", value: "flight" },
  { label: "Visa", value: "visa" },
  { label: "Bus", value: "bus" },
  { label: "Train", value: "train" },
  { label: "Cruise", value: "Cruise" },
  { label: "Cab", value: "cab" },
  { label: "Package", value: "package" },
  { label: "None", value: " " },
];
const phoneRegExp = /^(\+?91|0)?[6789]\d{9}$/;
const roomOption = new Array(20).fill().map((e, i) => {
  return { value: i + 1, label: i + 1 };
});
const adultsOption = new Array(40).fill().map((e, i) => {
  return { value: i + 1, label: i + 1 };
});
const childrenOption = new Array(40).fill().map((e, i) => {
  return { value: i + 1, label: i + 1 };
});
const infantsOption = new Array(20).fill().map((e, i) => {
  return { value: i + 1, label: i + 1 };
});
const classOption = [
  { value: "all", label: "All Class" },
  { value: "sleeper_class", label: "Sleeper Class" },
  { value: "third_ac", label: "Third AC" },
  { value: "second_ac", label: "Second AC" },
  { value: "first_ac", label: "First AC" },
  { value: "second_seating", label: "Second Seating" },
  { value: "vistadome_ac", label: "Vistadome AC" },
  { value: "ac_chair_car", label: "AC Chair Car" },
  { value: "first_class", label: "First Class" },
  { value: "third_ac_economy", label: "Third AC Economy" },
];
const AddLeads = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const blogId = new URLSearchParams(location.search).get("id");
  const [loading, setLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;

  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      description: "",
      destination_from: "",
      destination_to: "",
      check_in: "",
      check_out: "",
      room: "",
      adult: "",
      children: "",
      travel_from: "",
      travel_to: "",
      infants: "",
      train_class: "",
      pickup_time: "",
      drop_time: "",
      destination: "",
      inquiry_for: " ",
      visa_date: "",
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("Full Name must be required"),
      email: yup
        .string()
        .email("Please enter valid email")
        .required("Email name must be required"),
      phone_number: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone Numbwe is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      location.pathname.includes("add") ? addLead() : updateLead();
    },
  });
  const addLead = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${LeadAPI}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ ...values, is_notification: false }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };

  const updateLead = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${LeadAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  const getLeadById = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${LeadAPI}/get-by-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ _id: blogId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };

  useEffect(() => {
    if (!ignore && blogId) {
      getLeadById();
    }

    return () => {
      ignore = true;
    };
  }, [blogId]);

  return (
    <div className="bg-[#F9FAFB] h-screen px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Add Lead
          </h3>
        </div>
        <Button
          text="Back"
          onClick={() => {
            navigate("/super_admin/dashboard/lead");
          }}
        />
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="mt-10 flex flex-col gap-5">
          <Selection
            label="Inquiry Type"
            name="inquiry_type"
            option={queryOption}
            placeholder="Inquiry For"
            value={queryOption.find(
              (item) => item.value === values.inquiry_for
            )}
            onChange={(e) => {
              setFieldValue("inquiry_for", e.value);
            }}
          />
          <div className="flex w-full gap-5">
            <InputText
              type="text"
              id="full_name"
              label="Full Name"
              name="full_name"
              placeholder="Full Name"
              value={values?.full_name ?? ""}
              onChange={handleChange}
              error={errors.full_name && touched.full_name}
              errorText={errors.full_name}
            />
            <InputText
              type="text"
              id="email"
              label="Email"
              name="email"
              placeholder="Email"
              value={values?.email ?? ""}
              onChange={handleChange}
              error={errors.email && touched.email}
              errorText={errors.email}
            />
            <InputText
              type="number"
              id="phone_number"
              label="Phone Number"
              name="phone_number"
              placeholder="Phone Number"
              value={values?.phone_number ?? ""}
              onChange={handleChange}
              error={errors.phone_number && touched.phone_number}
              errorText={errors.phone_number}
            />
          </div>
          <TextAreaInput
            type="text"
            id="description"
            label="Description"
            name="description"
            placeholder="Description"
            value={values?.description ?? ""}
            onChange={handleChange}
            error={errors.description && touched.description}
            errorText={errors.description}
          />
          {(values.inquiry_for === "hotel" ||
            values.inquiry_for === "package") && (
            <HotelForm
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
          {values.inquiry_for === "flight" && (
            <FlightForm
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
          {(values.inquiry_for === "visa" ||
            values.inquiry_for === "Cruise") && (
            <VisaForm
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              inquiry_for={values.inquiry_for}
            />
          )}
          {values.inquiry_for === "bus" && (
            <BusForm
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
          {values.inquiry_for === "train" && (
            <TrainForm
              countryOptions={countryOptions}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}
          {values.inquiry_for === "cab" && (
            <CabForm
              countryOptions={countryOptions}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}
        </div>
      )}
      <div className="flex mt-5 gap-5 ">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
        >
          Save
        </button>
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="text-center w-full border border-blue-400 gap-2 items-center text-blue-500 font-bold py-[9px] px-4 rounded"
        >
          Cancel
        </button>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default AddLeads;

const HotelForm = ({ values, handleChange, setFieldValue }) => {
  return (
    <div>
      <div className="flex w-full gap-5">
        <DatePicker
          label="Check In"
          value={values?.travel_from}
          onChange={(e) => {
            setFieldValue("check_in", new Date(e[0]).toISOString());
          }}
          name="check_in"
          placeholder="Check In"
        />
        <DatePicker
          label="Check Out"
          value={values?.check_out}
          onChange={(e) => {
            setFieldValue("check_out", new Date(e[0]).toISOString());
          }}
          name="check_out"
          placeholder="Check Out"
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <InputText
          type="text"
          id="destination_from"
          label="Destination From"
          name="destination_from"
          placeholder="Destination From"
          value={values?.destination_from ?? ""}
          onChange={handleChange}
        />
        <Selection
          label="Rooms"
          name="room"
          option={roomOption}
          placeholder="Rooms"
          value={roomOption.find((item) => item.value === +values.room)}
          onChange={(e) => {
            setFieldValue("room", e.value?.toString());
          }}
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <Selection
          label="Adult"
          name="adult"
          option={adultsOption}
          placeholder="Adult"
          value={adultsOption.find((item) => item.value === +values.adult)}
          onChange={(e) => {
            setFieldValue("adult", e.value?.toString());
          }}
        />
        <Selection
          label="Children"
          name="children"
          option={childrenOption}
          placeholder="Children"
          value={childrenOption.find((item) => item.value === +values.children)}
          onChange={(e) => {
            setFieldValue("children", e.value?.toString());
          }}
        />
      </div>
    </div>
  );
};
const FlightForm = ({ values, handleChange, setFieldValue }) => {
  return (
    <div>
      <div className="flex w-full gap-5">
        <DatePicker
          label="Travel Date From"
          value={values?.travel_from}
          onChange={(e) => {
            setFieldValue("travel_from", new Date(e[0]).toISOString());
          }}
          name="travel_from"
          placeholder="Travel Date From"
        />
        <DatePicker
          label="Travel Date To"
          value={values?.travel_to}
          onChange={(e) => {
            setFieldValue("travel_to", new Date(e[0]).toISOString());
          }}
          name="travel_to"
          placeholder="Travel Date To"
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <InputText
          type="text"
          id="destination_from"
          label="Destination From"
          name="destination_from"
          placeholder="Destination From"
          value={values?.destination_from ?? ""}
          onChange={handleChange}
        />
        <InputText
          type="text"
          id="destination_to"
          label="Destination To"
          name="destination_to"
          placeholder="Destination To"
          value={values?.destination_to ?? ""}
          onChange={handleChange}
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <Selection
          label="Rooms"
          name="room"
          option={roomOption}
          placeholder="Rooms"
          value={roomOption.find((item) => item.value === +values.room)}
          onChange={(e) => {
            setFieldValue("room", e.value?.toString());
          }}
        />
        <Selection
          label="Children"
          name="children"
          option={childrenOption}
          placeholder="Children"
          value={childrenOption.find((item) => item.value === +values.children)}
          onChange={(e) => {
            setFieldValue("children", e.value?.toString());
          }}
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <Selection
          label="Adult"
          name="adult"
          option={adultsOption}
          placeholder="Adult"
          value={adultsOption.find((item) => item.value === +values.adult)}
          onChange={(e) => {
            setFieldValue("adult", e.value?.toString());
          }}
        />
        <Selection
          label="Infants (Below 2y)"
          name="infants"
          option={infantsOption}
          placeholder="Infants"
          value={infantsOption.find((item) => item.value === +values.infants)}
          onChange={(e) => {
            setFieldValue("infants", e.value?.toString());
          }}
        />
      </div>
    </div>
  );
};
const VisaForm = ({ values, handleChange, setFieldValue, inquiry_for }) => {
  return (
    <div>
      <div className="flex w-full gap-5">
        <InputText
          type="text"
          id="destination_from"
          label="Destination From"
          name="destination_from"
          placeholder="Destination From"
          value={values?.destination_from ?? ""}
          onChange={handleChange}
        />
        <DatePicker
          label="Date"
          value={values?.travel_from}
          onChange={(e) => {
            setFieldValue("travel_from", new Date(e[0]).toISOString());
          }}
          name="travel_from"
          placeholder="Date"
        />
      </div>
      {inquiry_for === "Cruise" && (
        <div className="mt-5">
          <Selection
            label="Adult"
            name="adult"
            option={adultsOption}
            placeholder="Adult"
            value={adultsOption.find((item) => item.value === +values.adult)}
            onChange={(e) => {
              setFieldValue("adult", e.value?.toString());
            }}
          />
        </div>
      )}
    </div>
  );
};
const BusForm = ({ values, handleChange, setFieldValue }) => {
  return (
    <div>
      <div className="flex w-full gap-5">
        <InputText
          type="text"
          id="destination_from"
          label="Destination From"
          name="destination_from"
          placeholder="Destination From"
          value={values?.destination_from ?? ""}
          onChange={handleChange}
        />
        <InputText
          type="text"
          id="destination_to"
          label="Destination To"
          name="destination_to"
          placeholder="Destination To"
          value={values?.destination_to ?? ""}
          onChange={handleChange}
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <DatePicker
          label="Travel Date"
          value={values?.travel_from}
          onChange={(e) => {
            setFieldValue("travel_from", new Date(e[0]).toISOString());
          }}
          name="travel_from"
          placeholder="Date"
        />
      </div>
      <div className="mt-5">
        <Selection
          label="Adult"
          name="adult"
          option={adultsOption}
          placeholder="Adult"
          value={adultsOption.find((item) => item.value === +values.adult)}
          onChange={(e) => {
            setFieldValue("adult", e.value?.toString());
          }}
        />
      </div>
    </div>
  );
};
const TrainForm = ({ values, handleChange, setFieldValue }) => {
  return (
    <div>
      <div className="flex w-full gap-5">
        <InputText
          type="text"
          id="destination_from"
          label="Destination From"
          name="destination_from"
          placeholder="Destination From"
          value={values?.destination_from ?? ""}
          onChange={handleChange}
        />
        <InputText
          type="text"
          id="destination_to"
          label="Destination To"
          name="destination_to"
          placeholder="Destination To"
          value={values?.destination_to ?? ""}
          onChange={handleChange}
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <Selection
          label="Class"
          name="train_class"
          option={classOption}
          placeholder="Class"
          value={classOption.find(
            (item) => item.label === values.destination_to
          )}
          onChange={(e) => {
            setFieldValue("train_class", e.label);
          }}
        />
        <Selection
          label="Adult"
          name="adult"
          option={adultsOption}
          placeholder="Adult"
          value={adultsOption.find((item) => item.value === +values.adult)}
          onChange={(e) => {
            setFieldValue("adult", e.value?.toString());
          }}
        />
      </div>
      <div className="mt-5">
        <DatePicker
          label="Travel Date"
          value={values?.travel_from}
          onChange={(e) => {
            setFieldValue("travel_from", new Date(e[0]).toISOString());
          }}
          name="travel_from"
          placeholder="Date"
        />
      </div>
    </div>
  );
};
const CabForm = ({ countryOptions, values, setFieldValue }) => {
  return (
    <div>
      <div className="flex w-full gap-5">
        <DatePicker
          label="Travel Date From"
          value={values?.travel_from}
          onChange={(e) => {
            setFieldValue("travel_from", new Date(e[0]).toISOString());
          }}
          name="travel_from"
          placeholder="Travel Date From"
        />
        <DatePicker
          label="Travel Date To"
          value={values?.travel_to}
          onChange={(e) => {
            setFieldValue("travel_to", new Date(e[0]).toISOString());
          }}
          name="travel_to"
          placeholder="Travel Date To"
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <Selection
          label="Destination From"
          name="inquiry_type"
          option={countryOptions}
          placeholder="Destination From"
          value={countryOptions.find(
            (item) => item.label === values.destination_from
          )}
          onChange={(e) => {
            setFieldValue("destination_from", e.label);
          }}
        />
        <Selection
          label="Destination To"
          name="destination_to"
          option={countryOptions}
          placeholder="Destination To"
          value={countryOptions.find(
            (item) => item.label === values.destination_to
          )}
          onChange={(e) => {
            setFieldValue("destination_to", e.label);
          }}
        />
      </div>
      <div className="flex w-full gap-5 mt-5">
        <DatePicker
          label="Pickup Time"
          value={values?.pickup_time}
          onChange={(e) => {
            setFieldValue("pickup_time", new Date(e[0]).toTimeString());
          }}
          name="pickup_time"
          placeholder="Pickup Time"
          isTime
        />
        <DatePicker
          label="Drop Time"
          value={values?.drop_time}
          onChange={(e) => {
            setFieldValue("drop_time", new Date(e[0]).toTimeString());
          }}
          name="drop_time"
          placeholder="Drop Time"
          isTime
        />
      </div>
      <div className="mt-5">
        <Selection
          label="Adult"
          name="adult"
          option={adultsOption}
          placeholder="Adult"
          value={adultsOption.find((item) => item.value === +values.adult)}
          onChange={(e) => {
            setFieldValue("adult", e.value?.toString());
          }}
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import ImageUplaod from "../Components/ImageUplaod";
import { HomePageAPI, ImageAPI } from "../API";
import { useFormik } from "formik";
import * as yup from "yup";
import InputText from "../Components/InputText";
import TextAreaInput from "../Components/TextArea";
import FloatNotification from "../Components/FloatNotification";
import Loading from "../Components/Loading";

const HomePage = () => {
  const [pageId, setPageId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    submitForm,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      home_banner: "",
      tag_line: "",
      description: "",
      experience_img: "",
      experience_title: "",
      experience_tagline: "",
      experience_description: "",
      experience_count: "",
      destination_collobration_count: "",
      happy_customer_count: "",
    },
    validationSchema: yup.object().shape({
      home_banner: yup.string().required("Home Banner must be required"),
      tag_line: yup.string().required("Tag line must be required"),
      description: yup.string().required("Description line must be required"),
      experience_img: yup
        .string()
        .required("Experience image must be required"),
      experience_title: yup
        .string()
        .required("Experience title must be required"),
      experience_tagline: yup
        .string()
        .required("Experience line must be required"),
      experience_description: yup
        .string()
        .required("Experience line must be required"),
      experience_count: yup
        .string()
        .required("Experience count must be required"),
      destination_collobration_count: yup
        .string()
        .required("Destination collobration must be required"),
      happy_customer_count: yup
        .string()
        .required("Happy customer count must be required"),
    }),
    onSubmit: () => {
      updateHomePage();
    },
  });

  const getHomePage = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(HomePageAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
            setPageId(data.data._id);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const updateHomePage = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${HomePageAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          _id: pageId,
          ...values,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            setShowNotification({
              show: true,
              message: <div>Data Updated Success Fully</div>,
              title: "Update",
              status: "success",
            });
            getHomePage();
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!ignore) {
      getHomePage();
    }
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div className="px-4 py-4">
      <div className="lg:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 lg:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Home Section
          </h3>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div>
          <div className="py-4">
            {/* <ImageUplaod
              value={values.home_banner ?? ""}
              onChange={(e) => {
                upload("home_banner", e);
              }}
              label={"Home Banner"}
              error={errors?.home_banner && touched?.home_banner ? true : false}
              errorText={errors.home_banner}
            /> */}
            <div className="flex flex-col gap-5">
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="tag_line"
                  label="Tag Line"
                  name="tag_line"
                  placeholder="Tag Line"
                  value={values?.tag_line ?? ""}
                  onChange={handleChange}
                  error={errors.tag_line && touched.tag_line ? true : false}
                  errorText={errors.tag_line}
                />
              </div>
              <TextAreaInput
                type="text"
                id="description"
                label="Description"
                name="description"
                placeholder="Description"
                value={values?.description ?? ""}
                onChange={handleChange}
                error={errors.description && touched.description}
                errorText={errors.description}
              />
            </div>
          </div>
          <div className="lg:flex items-center justify-between mb-4 gap-3">
            <div className="mb-4 lg:mb-0">
              <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
                Section 2
              </h3>
            </div>
          </div>
          <div className="py-4">
            {/* <ImageUplaod
              value={values.experience_img ?? ""}
              onChange={(e) => {
                upload("experience_img", e);
              }}
              label={"Experience Image"}
              error={errors?.experience_img && touched?.experience_img}
              errorText={errors.experience_img}
            /> */}
            <div className="flex flex-col gap-5">
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="experience_title"
                  label="Experience Title"
                  name="experience_title"
                  placeholder="Experience Title"
                  value={values?.experience_title ?? ""}
                  onChange={handleChange}
                  error={errors.experience_title && touched.experience_title}
                  errorText={errors.experience_title}
                />
                <InputText
                  type="text"
                  id="experience_tagline"
                  label="Experience Tag Line"
                  name="experience_tagline"
                  placeholder="Experience Tag Line"
                  value={values?.experience_tagline ?? ""}
                  onChange={handleChange}
                  error={
                    errors.experience_tagline && touched.experience_tagline
                  }
                  errorText={errors.experience_tagline}
                />
              </div>
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="experience_count"
                  label="Experience Count"
                  name="experience_count"
                  placeholder="Experience Count"
                  value={values?.experience_count ?? ""}
                  onChange={handleChange}
                  error={errors.experience_count && touched.experience_count}
                  errorText={errors.experience_count}
                />
                <InputText
                  type="text"
                  id="destination_collobration_count"
                  label="Destination Collobration Count"
                  name="destination_collobration_count"
                  placeholder="Destination Collobration Count"
                  value={values?.destination_collobration_count ?? ""}
                  onChange={handleChange}
                  error={
                    errors.destination_collobration_count &&
                    touched.destination_collobration_count
                  }
                  errorText={errors.destination_collobration_count}
                />
              </div>
              <div className="flex w-full gap-5">
                <InputText
                  type="text"
                  id="happy_customer_count"
                  label="Happy Customer Count"
                  name="happy_customer_count"
                  placeholder="Happy Customer Count"
                  value={values?.happy_customer_count ?? ""}
                  onChange={handleChange}
                  error={
                    errors.happy_customer_count && touched.happy_customer_count
                  }
                  errorText={errors.happy_customer_count}
                />
              </div>
              <TextAreaInput
                type="text"
                id="experience_description"
                label="Experience Description"
                name="experience_description"
                placeholder="Experience Description"
                value={values?.experience_description ?? ""}
                onChange={handleChange}
                error={
                  errors.experience_description &&
                  touched.experience_description
                }
                errorText={errors.experience_description}
              />
            </div>
            <div className="flex mt-5 gap-5 ">
              <button
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
                className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default HomePage;

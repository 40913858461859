import {
  IconCalendarStats,
  IconLocation,
  IconMapPin,
} from "@tabler/icons-react";
import React, { useState } from "react";
import HeroSelection from "./HeroSelection";
import HeroDatePicker from "./HeroDatePicker";
import { ContextStore } from "../context/WebContext";
import { useNavigate } from "react-router-dom";
import HeroInput from "./HeroInput";

const VisaForm = ({ countryOptions }) => {
  const navigate = useNavigate();
  const [showOption, setShowOption] = useState(false);
  const { formDetails, setFormDetails } = ContextStore();

  return (
    <div>
      <div className="grid justify-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-10 xl:gap-12 px-10 2xl:justify-items-start py-5">
        <HeroInput
          Icon={IconLocation}
          type="text"
          id="destination_from"
          label="Destination From *"
          name="destination_from"
          placeholder="Destination From"
          value={formDetails.destination_from ?? ""}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              destination_from: e.target.value,
            });
          }}
        />
        <HeroDatePicker
          label="Date *"
          placeholder={"Check In"}
          Icon={IconCalendarStats}
          value={formDetails?.visa_date}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              visa_date: new Date(e[0]).toISOString(),
            });
          }}
        />
      </div>
    </div>
  );
};

export default VisaForm;

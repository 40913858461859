import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
const HeroDatePicker = ({
  label,
  Icon,
  isTime,
  onChange,
  value,
  placeholder,
}) => {
  return (
    <div className="flex items-center gap-3">
      <Icon className="h-6 w-6 text-[#FD6469]" />
      <div className="w-44">
        <label className="capitalize block text-sm text-gray-500 mb-[5px]">
          {label}
        </label>
        <Flatpickr
          options={isTime ? { enableTime: true, noCalendar: true } : {}}
          value={value}
          className="date_picker focus-visible:outline-none focus-visible:ring-0 bg-red-50 border-b pb-1 border-b-red-500"
          placeholder={placeholder}
          onChange={(date) => {
            onChange(date);
          }}
        />
      </div>
    </div>
  );
};

export default HeroDatePicker;

import {
  UserIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  EnvelopeIcon,
  PhoneIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import PrefixInput, { PrefixTextInput } from "../Components/PrefixInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { LeadAPI } from "../API";
import { useState } from "react";
import FloatNotification from "./FloatNotification";
import { useNavigate } from "react-router-dom";
import { ContextStore } from "../context/WebContext";
const Booking = ({ destination }) => {
  const navigate = useNavigate();
  const { setLoading } = ContextStore();
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("Full name is required"),
      email: yup.string().required("Email name is required"),
      phone_number: yup.string().required("Phone Number role is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      addLead();
    },
  });
  const addLead = () => {
    setLoading(true);
    fetch(`${LeadAPI}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        full_name: values.full_name,
        phone_number: values.phone_number,
        email: values.email,
        description: values.description,
        destination: destination,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          setShowNotification({
            show: true,
            message: <div>Lead Added Our Staf Will Contact You</div>,
            title: "Add",
            status: "success",
          });
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
      });
  };
  return (
    <div className="w-full xl:flex lg:justify-center flex-col">
      <div
        onClick={() => {
          window.open("https://wa.me/+918866771988", "_blank");
        }}
        className="my-5 w-full md:w-80 xl:w-full cursor-pointer bg-green-500 py-2 rounded-lg text-white font-semibold text-center"
      >
        Whatsapp
      </div>
      <div className="bg-[#FD6469]/30 px-10 rounded-xl border-l-2 border-t-2 py-10 border-l-[#FD6469] border-t-[#FD6469]">
        <div className="text-[#FD6469] font-semibold text-2xl text-center">
          Book This Tour
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-5">
            <PrefixInput
              id="full_name"
              name="full_name"
              placeholder="Full Name"
              value={values?.full_name ?? ""}
              onChange={handleChange}
              error={errors.full_name && touched.full_name}
              errorText={errors.full_name}
              Icon={UserIcon}
            />
          </div>
          <div className="mt-5">
            <PrefixInput
              id="email"
              name="email"
              placeholder="Email"
              value={values?.email ?? ""}
              onChange={handleChange}
              error={errors.email && touched.email}
              errorText={errors.email}
              Icon={EnvelopeIcon}
            />
          </div>
          <div className="mt-5">
            <PrefixInput
              id="phone_number"
              name="phone_number"
              placeholder="Phone Number"
              value={values?.phone_number ?? ""}
              onChange={handleChange}
              error={errors.phone_number && touched.phone_number}
              errorText={errors.phone_number}
              Icon={PhoneIcon}
            />
          </div>
          <div className="mt-5">
            <PrefixTextInput
              id="description"
              name="description"
              placeholder="Description"
              value={values?.description ?? ""}
              onChange={handleChange}
              error={errors.description && touched.description}
              errorText={errors.description}
            />
          </div>
          <div className="mt-10 space-y-8">
            <div className="bg-[#FD6469] text-white font-semibold text-center py-1.5 rounded">
              <button type="sybmit" className="capitalize">
                book now
              </button>
            </div>
          </div>
        </form>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};
export default Booking;

import React, { useEffect, useState } from "react";
import ImageUplaod from "../Components/ImageUplaod";
import { BlogPageAPI, ImageAPI } from "../API";
import { useFormik } from "formik";
import * as yup from "yup";
import InputText from "../Components/InputText";
import TextAreaInput from "../Components/TextArea";
import FloatNotification from "../Components/FloatNotification";
import Loading from "../Components/Loading";

const BlogPage = () => {
  const [pageId, setPageId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    submitForm,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      blog_banner: "",
      title: "",
      tag_line: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      blog_banner: yup.string().required("Blog banner must be required"),
      title: yup.string().required("Title must be required"),
      tag_line: yup.string().required("Tag line must be required"),
      description: yup.string().required("Description line must be required"),
    }),
    onSubmit: () => {
      updateBlogPage();
    },
  });
  const upload = async (name, file) => {
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    setLoading(true);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setLoading(false);
      });
  };
  const getBlogPage = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(BlogPageAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
            setPageId(data.data._id);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const updateBlogPage = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${BlogPageAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          _id: pageId,
          ...values,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            setShowNotification({
              show: true,
              message: <div>Data Updated Success Fully</div>,
              title: "Update",
              status: "success",
            });
            getBlogPage();
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!ignore) {
      getBlogPage();
    }
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div className="px-4 py-4">
      <div className="lg:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 lg:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Blog Section 
          </h3>
        </div>
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="py-4">
          {/* <ImageUplaod
            value={values.blog_banner ?? ""}
            onChange={(e) => {
              upload("blog_banner", e);
            }}
            label={"Blog Banner"}
            error={errors?.blog_banner && touched?.blog_banner ? true : false}
            errorText={errors.blog_banner}
          /> */}
          <div className="flex flex-col gap-5">
            <div className="flex w-full gap-5">
              <InputText
                type="text"
                id="title"
                label="Title"
                name="title"
                placeholder="Title"
                value={values?.title ?? ""}
                onChange={handleChange}
                error={errors.title && touched.title}
                errorText={errors.title}
              />
              <InputText
                type="text"
                id="tag_line"
                label="Tag Line"
                name="tag_line"
                placeholder="Tag Line"
                value={values?.tag_line ?? ""}
                onChange={handleChange}
                error={errors.tag_line && touched.tag_line ? true : false}
                errorText={errors.tag_line}
              />
            </div>
            <TextAreaInput
              type="text"
              id="description"
              label="Description"
              name="description"
              placeholder="Description"
              value={values?.description ?? ""}
              onChange={handleChange}
              error={errors.description && touched.description}
              errorText={errors.description}
            />
          </div>
        </div>
      )}
      <div className="flex mt-5 gap-5 ">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
        >
          Update
        </button>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default BlogPage;

import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Button from "../Components/Button";
import Loading from "../Components/Loading";
import Model from "../Components/Model";
import InputText from "../Components/InputText";
import { useFormik } from "formik";
import * as yup from "yup";
import { DreamPLaceAPI, ImageAPI } from "../API";
import FloatNotification from "../Components/FloatNotification";
import DeleteModel from "../Components/DeleteModel";
import Pagination from "../Components/Pagination";
import Selection from "../Components/Selection";
import ImageUplaod from "../Components/ImageUplaod";
import RadioSelection from "../Components/RadioSelection";
import LazyImage from "../Components/LazyImage";

const DremPlace = () => {
  const [page, setPage] = useState(1);
  const [showModel, setShowModel] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [dremPlaceData, setDremPlaceData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dreamPlaceId, setDreamPlaceId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [imgErr, setImgErr] = useState("");
  const selectOption = Array.from(Array(6).keys()).map((item) => {
    return { value: `${item + 1}`, label: `${item + 1}` };
  });
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const [loading, setLoading] = useState(false);
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    submitForm,
    handleSubmit,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      img: "",
      index: "1",
    },
    validationSchema: yup.object().shape({
      img: yup.string().required("Image must be required"),
      index: yup.string().required("Index must be required"),
    }),
    onSubmit: () => {
      isEdit ? updateDreamPlace() : addDreamPlace();
    },
  });
  const getAllDreamPlace = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(DreamPLaceAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ page: page }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setDremPlaceData(data.data);
            setCount(data.counts);
            setPageCount(data.page);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const addDreamPlace = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setModelLoading(true);
      fetch(`${DreamPLaceAPI}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          img: values.img,
          index: values.index,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            setShowModel(false);
            getAllDreamPlace();
            resetForm();
            setImgErr("");
          } else {
            setImgErr(data.error);
          }
          setModelLoading(false);
        });
    }
  };
  const updateDreamPlace = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setModelLoading(true);
      fetch(`${DreamPLaceAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          _id: dreamPlaceId,
          img: values.img,
          index: values.index,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            setIsEdit(false);
            getAllDreamPlace();
            resetForm();
            setImgErr("");
          } else {
            setImgErr(data.error);
          }
          setShowModel(false);
          setModelLoading(false);
        });
    }
  };
  const deleteDreamPlace = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setModelLoading(true);
      fetch(`${DreamPLaceAPI}/delete `, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          id: dreamPlaceId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            getAllDreamPlace();
            setDeleteModal(false);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setModelLoading(false);
        });
    }
  };
  const upload = async (name, file) => {
    const token = localStorage.getItem("admin_token");
    const formData = new FormData();
    formData.append("photo", file);
    setModelLoading(true);
    fetch(ImageAPI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setFieldValue(name, data?.url);
          setImgErr("");
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
        setModelLoading(false);
      });
  };
  useEffect(() => {
    if (!ignore) {
      getAllDreamPlace();
    }

    return () => {
      ignore = true;
    };
  }, [page]);

  return (
    <div className="px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Dream Place
          </h3>
        </div>
        <Button
          Icon={PlusIcon}
          text="Add Dream Place"
          onClick={() => {
            setShowModel(true);
          }}
        />
      </div>
      <div className="py-4">
        <div className="inline-block w-full shadow rounded-lg overflow-x-auto destination-nav">
          <table className="w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  Image
                </th>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  Index
                </th>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dremPlaceData.length > 0 ? (
                dremPlaceData.map((dreamplace, index) => (
                  <tr key={index}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex-shrink-0 w-10 h-10">
                        <LazyImage
                          className="w-10 h-10 object-cover object-center rounded-full"
                          src={dreamplace.img}
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap capitalize">
                        {dreamplace.index}{" "}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center gap-5">
                        <button>
                          <PencilIcon
                            onClick={() => {
                              setValues({
                                img: dreamplace.img,
                                index: dreamplace.index,
                              });
                              setDreamPlaceId(dreamplace._id);
                              setShowModel(true);
                              setIsEdit(true);
                            }}
                            className="h-5 w-5 text-blue-500"
                          />
                        </button>
                        <button>
                          <TrashIcon
                            onClick={() => {
                              setDreamPlaceId(dreamplace._id);
                              setDeleteModal(true);
                            }}
                            className="h-5 w-5 text-red-500"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="px-5 py-5  bg-white text-sm">
                    <div className="w-full text-gray-900 capitalize flex justify-center">
                      No Data Found In Dream Place
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            count={count}
            page={page}
            pageCount={pageCount}
            setPage={setPage}
            data={dremPlaceData}
          />
        </div>
      </div>
      <DeleteModel
        handleDelete={deleteDreamPlace}
        setShowModal={setDeleteModal}
        title={"Delete Dream Place"}
        showModal={deleteModal}
        loading={modelLoading}
      />
      <Model
        setShowModel={setShowModel}
        title={isEdit ? "Edit Dream Place" : "Add Dream Place"}
        showModel={showModel}
      >
        <form onSubmit={handleSubmit}>
          <div className="mt-5">
            <Selection
              label="Select Place"
              name="index"
              option={selectOption}
              value={selectOption.find((item) => item.value === values.index)}
              onChange={(e) => {
                setFieldValue("index", e.value);
              }}
            />
          </div>
          <div className="my-5">
            <ImageUplaod
              value={values.img ?? ""}
              onChange={(e) => {
                upload("img", e);
              }}
              label={"Dream Image"}
              error={(errors?.img && touched?.img) || imgErr ? true : false}
              errorText={imgErr ? imgErr : errors.img}
            />
          </div>
          <div className="mt-5">
            {modelLoading ? (
              <div className="flex justify-center">
                <div className="h-10 w-20">
                  <Loading />
                </div>
              </div>
            ) : (
              <div className="flex gap-5">
                <button
                  type="submit"
                  className="bg-blue-500 flex gap-2 items-center hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowModel(false);
                    resetForm();
                    setImgErr("");
                  }}
                  className=" flex gap-2 items-center border-2 border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </Model>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default DremPlace;

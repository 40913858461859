import React from "react";

import { IconPhotoHeart } from "@tabler/icons-react";
import { ContextStore } from "../context/WebContext";
import {
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import LazyImage from "./LazyImage";

const PackageHero = ({ children, banner, name }) => {
  const { setBookType, bookType } = ContextStore();

  const navItem = [
    { name: "information", active_name: "information", icon: DocumentTextIcon },
    { name: "tour plan", active_name: "tour_plan", icon: CalendarIcon },
    { name: "gallery", active_name: "gallery", icon: IconPhotoHeart },
    { name: "reviews", active_name: "reviews", icon: ChatBubbleLeftRightIcon },
  ];
  return (
    <div>
      <div className="relative w-full">
        <LazyImage
          src={banner}
          alt="Background Image"
          className="object-cover object-center w-full h-[600px]"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h1 className="text-3xl text-white font-bold font-lobster">
            Amazing Tour
          </h1>
          <p className="text-white mt-4 font-inter text-6xl">{name}</p>
        </div>
        <div className="absolute flex justify-center w-full bottom-0">
          <div className="destination-nav bg-white overflow-x-auto py-2 rounded-tl-xl rounded-tr-xl mx-5 md:mx-10 lg:mx-0 w-full md:w-4/5 lg:w-4/5 2xl:w-2/4 flex justify-between md:justify-center items-center px-5 gap-6 md:gap-0">
            {navItem.map((item, ind) => (
              <div
                key={ind}
                onClick={() => setBookType(item.active_name)}
                className={`flex text-nowrap h-10 my-3 justify-center cursor-pointer w-96 ${
                  ind < 3 && "border-r-2 border-gray-300 pr-6 md:pr-0"
                } hover:text-[#FD6469] items-center gap-5 ${
                  item.active_name === bookType
                    ? "text-[#FD6469]"
                    : "text-gray-500"
                }`}
              >
                <item.icon className="h-5 w-5" />
                <span className={`uppercase text-sm font-rubik`}>
                  {item.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PackageHero;

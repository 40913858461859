import {
  IconCalendarStats,
  IconLocation,
  IconMapPin,
  IconUser,
} from "@tabler/icons-react";
import React, { useState } from "react";
import HeroSelection from "./HeroSelection";
import HeroDatePicker from "./HeroDatePicker";
import { ContextStore } from "../context/WebContext";
import { useNavigate } from "react-router-dom";
import HeroInput from "./HeroInput";

const TrainForm = ({ countryOptions }) => {
  const navigate = useNavigate();
  const [showOption, setShowOption] = useState(false);
  const { formDetails, setFormDetails } = ContextStore();
  const classOption = [
    { value: "all", label: "All Class" },
    { value: "sleeper_class", label: "Sleeper Class" },
    { value: "third_ac", label: "Third AC" },
    { value: "second_ac", label: "Second AC" },
    { value: "first_ac", label: "First AC" },
    { value: "second_seating", label: "Second Seating" },
    { value: "vistadome_ac", label: "Vistadome AC" },
    { value: "ac_chair_car", label: "AC Chair Car" },
    { value: "first_class", label: "First Class" },
    { value: "third_ac_economy", label: "Third AC Economy" },
  ];
  const adultsOption = new Array(40).fill().map((e, i) => {
    return { value: i + 1, label: i + 1 };
  });
  return (
    <div>
      <div className="grid justify-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-10 xl:gap-12 px-10 2xl:justify-items-start py-5">
        <HeroInput
          Icon={IconLocation}
          type="text"
          id="destination_from"
          label="Destination From *"
          name="destination_from"
          placeholder="Destination From"
          value={formDetails.destination_from ?? ""}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              destination_from: e.target.value,
            });
          }}
        />
        <HeroInput
          Icon={IconMapPin}
          type="text"
          id="destination_to"
          label="Destination To *"
          name="destination_to"
          placeholder="Destination To"
          value={formDetails.destination_to ?? ""}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              destination_to: e.target.value,
            });
          }}
        />
        <HeroSelection
          Icon={IconUser}
          showOption={showOption}
          label="Adults *"
          option={adultsOption}
          value={adultsOption?.find((c) => c.value === +formDetails.adult)}
          onChange={(e) => {
            setFormDetails({ ...formDetails, adult: e.value.toString() });
          }}
        />
        <HeroDatePicker
          Icon={IconCalendarStats}
          label="Travel Date *"
          placeholder={"Check In"}
          value={formDetails?.travel_from}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              travel_from: new Date(e[0]).toISOString(),
            });
          }}
        />
        <HeroSelection
          Icon={IconMapPin}
          label="Class *"
          option={classOption}
          value={classOption?.find((c) => c.label === formDetails.train_class)}
          onChange={(e) => {
            setFormDetails({ ...formDetails, train_class: e.label });
          }}
        />
      </div>
    </div>
  );
};

export default TrainForm;

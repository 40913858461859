import React, { useState } from "react";
import mapImg from "../images/map.png";
import Heading from "./Heading";
import HotelForm from "./HotelForm";
import FlightForm from "./FlightForm";
import BusForm from "./BusForm";
import TrainForm from "./TrainForm";
import CabsForm from "./CabsForm";
import PackageForm from "./PackageForm";
import VisaForm from "./VisaForm";
import CruiseForm from "./CruiseForm";
import { useNavigate } from "react-router-dom";
import WebLoading from "./WebLoading";
import QueryModel from "./QueryModel";
import { ContextStore, queryForm } from "../context/WebContext";
import FloatNotification from "./FloatNotification";
import LazyImage from "./LazyImage";
import home_banner from "../images/home_image.jpg";
const HeroSection = ({
  content,
  selectedOption,
  setSelectedOption,
  queryOption,
  countryOptions,
  formLoading,
}) => {
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const { formDetails, setFormDetails } = ContextStore();
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  return (
    <div>
      <section className="text-gray-600 body-font ">
        <div className="flex xl:flex-row flex-col items-center">
          <div className="w-full order-1 xl:order-2">
            <img
              className="object-cover object-center w-full"
              alt="hero"
              src={home_banner}
            />
          </div>
          <div className="order-2 xl:order-1 relative w-full flex justify-center xl:justify-start">
            <LazyImage
              className="w-full h-[400px] object-cover md:h-auto"
              src={mapImg}
              alt=""
            />
            <div className="lg:flex-grow lg:w-full flex flex-col gap-4 md:gap-8 md:pl-20 lg:justify-center h-full xl:items-start xl:text-left mb-16 md:mb-0 items-center text-center absolute top-0">
              <div className="flex gap-4 items-center xl:text-start">
                <Heading heading={"Explore The World"} />
              </div>
              <div className="capitalize w-full flex justify-center xl:justify-start">
                <h1 className="font-poppins text-2xl md:text-4xl xl:text-[48px] md:w-3/4 lg:w-2/4 xl:w-5/6 2xl:w-4/6 !leading-tight font-bold text-[#1A093F] capitalize 2xl:text-5xl">
                  {content?.tag_line}
                </h1>
              </div>
              <div>
                <p className="capitalize text-justify text-sm text-gray-500 font-normal px-5 md:px-40 xl:px-0 xl:w-full 2xl:w-4/6 xl:text-lg 2xl:text-xl">
                  {content?.description}
                </p>
              </div>
              <div className="flex flex-col md:flex-row justify-center items-center gap-10">
                <button
                  onClick={() => {
                    navigate("/tour-list");
                  }}
                  className="bg-[#FD5056] shadow-lg shadow-[#FD5056] hover:text-[#FD5056] transition-all duration-300 hover:bg-white text-white rounded-full px-5 py-3 font-medium font-rubik"
                >
                  Discover Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mx-10 lg:mx-20 mt-12">
        <div className="bg-red-50 rounded-xl relative">
          <div className="w-full flex justify-center absolute -top-8 lg:-top-12">
            <div className="bg-white  rounded-lg shadow-xl py-2 w-64 md:w-auto px-5 lg:w-3/5 2xl:w-2/4 overflow-x-auto destination-nav">
              <div className="flex xl:justify-between xl:px-8 items-start gap-10 lg:gap-5">
                {queryOption.map((item, ind) => (
                  <div
                    key={ind}
                    onClick={() => {
                      setSelectedOption(item.selection_name);
                      setFormDetails(queryForm);
                    }}
                    className={`flex flex-col cursor-pointer items-center pb-2 font-semibold ${
                      item.selection_name === selectedOption
                        ? "border-b-red-500 border-b-2 text-red-500"
                        : ""
                    }`}
                  >
                    <img
                      className="w-8 h-8 lg:w-10 lg:h-10 xl:h-14 xl:w-14 object-contain"
                      src={item.icon}
                    />
                    <p className="text-center text-xs xl:text-sm">
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="py-16">
            {formLoading ? (
              <div className="w-full flex justify-center">
                <WebLoading width="h-40 w-40" />
              </div>
            ) : (
              <>
                {selectedOption === "hotel" && (
                  <HotelForm countryOptions={countryOptions} />
                )}
                {selectedOption === "flight" && (
                  <FlightForm countryOptions={countryOptions} />
                )}
                {selectedOption === "visa" && (
                  <VisaForm countryOptions={countryOptions} />
                )}
                {selectedOption === "Cruise" && (
                  <CruiseForm countryOptions={countryOptions} />
                )}
                {selectedOption === "bus" && (
                  <BusForm countryOptions={countryOptions} />
                )}
                {selectedOption === "train" && (
                  <TrainForm countryOptions={countryOptions} />
                )}
                {selectedOption === "cab" && (
                  <CabsForm countryOptions={countryOptions} />
                )}
                {selectedOption === "package" && (
                  <PackageForm countryOptions={countryOptions} />
                )}
              </>
            )}
          </div>
          <div className="absolute -bottom-5 flex justify-center w-full">
            <button
              onClick={() => {
                const isEmpty = [];
                const validationFeild = formDataType.find(
                  (item) => item.type === selectedOption
                ).data;
                validationFeild.map((item) => {
                  !formDetails[item] && isEmpty.push(item);
                });
                isEmpty.length === 0
                  ? setShowModel(true)
                  : setShowNotification({
                      show: true,
                      message: (
                        <div className="capitalize">
                          {isEmpty.map((item, index) => (
                            <div key={index}>
                              {index + 1} please select{" "}
                              {item.split("_").join(" ")}
                            </div>
                          ))}
                        </div>
                      ),
                      title: "Fill Data",
                      status: "failed",
                    });
              }}
              className="bg-[#FD5056] shadow-lg shadow-[#FD5056] hover:text-[#FD5056] transition-all duration-300 hover:bg-white text-white rounded-full px-10 py-3 font-medium font-rubik"
            >
              Inquire Now
            </button>
          </div>
          <QueryModel
            showModel={showModel}
            setShowModel={setShowModel}
            setShowNotification={setShowNotification}
          />
        </div>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default HeroSection;

const formDataType = [
  {
    type: "hotel",
    data: [
      "destination_from",
      "check_in",
      "check_out",
      "room",
      "adult",
      "children",
    ],
  },
  {
    type: "flight",
    data: [
      "destination_from",
      "destination_to",
      "travel_from",
      "travel_to",
      "adult",
      "children",
      "infants",
    ],
  },
  {
    type: "visa",
    data: ["destination_from", "check_in"],
  },
  {
    type: "bus",
    data: ["destination_from", "destination_to", "adult", "travel_from"],
  },
  {
    type: "train",
    data: [
      "destination_from",
      "destination_to",
      "adult",
      "travel_from",
      "train_class",
    ],
  },
  {
    type: "Cruise",
    data: ["destination_from", "travel_from", "adult"],
  },
  {
    type: "cab",
    data: [
      "destination_from",
      "destination_to",
      "travel_from",
      "travel_to",
      "pickup_time",
      "drop_time",
      "adult",
    ],
  },
  {
    type: "package",
    data: [
      "destination_from",
      "check_in",
      "check_out",
      "room",
      "adult",
      "children",
    ],
  },
];

import React, { useEffect, useState } from "react";

import {
  CategoryAPI,
  FavouriteDestinationAPI,
  LeadAPI,
  TourListAPI,
} from "../API";
import { useNavigate } from "react-router-dom";
import FloatNotification from "../Components/FloatNotification";
import {
  ChartBarSquareIcon,
  DocumentTextIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import Loading from "../Components/Loading";

const Dashboard = () => {
  let ignore = false;
  const navigate = useNavigate();
  const [destinationData, setDestinationData] = useState([]);
  const [favDestinationCount, setFavDestinationCount] = useState(0);
  const [tourListData, setTourListData] = useState([]);
  const [topDestinationCount, setTopDestinationCount] = useState(0);
  const [leadData, setLeadData] = useState([]);
  const [leadCount, setLeadCount] = useState(0);
  const [tabType, setTabType] = useState("Favourite Destination");
  const [link, setLink] = useState(
    "/super_admin/dashboard/favourite-destination"
  );
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const cardData = [
    {
      name: "Favourite Destination",
      total: favDestinationCount,
      icon: ChartBarSquareIcon,
      link: "/super_admin/dashboard/favourite-destination",
      bg: "bg-red-100",
      iconBg: "bg-[#FA5A7D] p-1.5",
    },
    {
      name: "Top Destination",
      total: topDestinationCount,
      icon: DocumentTextIcon,
      link: "/super_admin/dashboard/top-destination",
      bg: "bg-orange-100",
      iconBg: "bg-[#FF947A] p-2",
    },
    {
      name: "Lead",
      total: leadCount,
      icon: UserPlusIcon,
      link: "/super_admin/dashboard/lead",
      bg: "bg-violet-100",
      iconBg: "bg-[#BF83FF] p-2",
    },
  ];

  const [loading, setLoading] = useState(false);
  const getAllFavDestination = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(FavouriteDestinationAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setDestinationData(data.data);
            setFavDestinationCount(data.counts);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const getAllTourList = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(TourListAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setTourListData(data.data);
            setTopDestinationCount(data.counts);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const getAllLeads = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(LeadAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setLeadData(data.data);
            setLeadCount(data.counts);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };

  useEffect(() => {
    if (!ignore) {
      getAllFavDestination();
      getAllTourList();
      getAllLeads();
    }

    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div className=" px-4 py-4">
      <div className="lg:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 lg:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Dashboard
          </h3>
        </div>
      </div>
      <div className="w-full bg-white p-5 rounded-xl">
        <h3 className="font-semibold text-lg text-indigo-900 font-poppins">
          Your Today's Bord
        </h3>
        <div className="text-blue-500">View your leads & summary</div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 my-5">
          {cardData.map((item, ind) => (
            <div
              key={ind}
              onClick={() => navigate(item?.link)}
              className={`cursor-pointer rounded-xl px-5 py-5 ${item.bg} font-poppins`}
            >
              <div>
                <div className={``}>
                  <item.icon
                    className={`font-semibold h-9 w-9 ${item.iconBg} text-white rounded-full p-1.5`}
                  />
                </div>
                <div className="mt-5">
                  <h6 className="font-semibold text-xl capitalize text-indigo-900">
                    {item.name}
                  </h6>
                  <div className="items-center">
                    <p className="capitalize text-lg text-gray-600">
                      Total {item?.total}
                    </p>
                    <p className="lowercase text-blue-500">
                      {item?.name} you have
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="my-5 space-y-5">
        <section>
          <div className="flex justify-between items-center gap-8 text-center border-b md:justify-start border-gray-200 text-gray-500">
            <div className="flex w-full gap-8">
              {cardData.map((item, index) => (
                <div key={index}>
                  <button
                    onClick={() => {
                      setTabType(item.name);
                      setLink(item.link);
                    }}
                    className={`flex capitalize justify-start border-b-4 border-transparent hover:text-blue-500 hover:border-blue-500 ${
                      item.name === tabType &&
                      "text-blue-500 border-b-blue-500 "
                    } py-4`}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
            <div
              onClick={() => navigate(link)}
              className="w-24 border-2 rounded-lg border-blue-500 py-1 text-blue-500"
            >
              View All
            </div>
          </div>
          <div className="w-full bg-white p-5 rounded-xl my-10">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {tabType !== "Lead" && (
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Image
                    </th>
                  )}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  {tabType !== "Lead" && (
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                  )}
                  {tabType === "Lead" && (
                    <>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Phone Number
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td colSpan={5} className="py-5">
                      <div className="w-full flex justify-center">
                        <div className="w-20">
                          <Loading />
                        </div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {tabType === "Favourite Destination" &&
                      destinationData.slice(0, 5).map((item, ind) => (
                        <tr key={ind}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <img
                              className="w-10 h-10 rounded-full object-cover"
                              src={item?.banner_img}
                              alt=""
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.information?.destination_name}
                          </td>
                          <td className="px-6 my-2 line-clamp-2 w-48 text-wrap">
                            {item?.information?.description}
                          </td>
                        </tr>
                      ))}
                    {tabType === "Top Destination" &&
                      tourListData.slice(0, 5).map((item, ind) => (
                        <tr key={ind}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <img
                              className="w-10 h-10 rounded-full"
                              src={item?.banner_img}
                              alt=""
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.information?.destination_name}
                          </td>
                          <td className="px-6 my-2 line-clamp-2 w-48 text-wrap">
                            {item?.information?.description}
                          </td>
                        </tr>
                      ))}
                    {tabType === "Lead" &&
                      leadData.slice(0, 5).map((item, ind) => (
                        <tr key={ind}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.full_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.email}
                          </td>
                          <td className="px-6 py-4 w-48 text-wrap">
                            {item?.phone_number}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default Dashboard;

import React from "react";
import earthImg from "../images/earth.png";

const Heading = ({ heading }) => {
  return (
    <>
      <h1 className="capitalize text-[#FD6469] text-xl font-medium font-rubik">
        {heading}
      </h1>
      <img src={earthImg} className="h-6 w-6" />
    </>
  );
};

export default Heading;

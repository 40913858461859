import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import ImageUplaod from "../Components/ImageUplaod";
import InputText from "../Components/InputText";
import * as yup from "yup";
import { useFormik } from "formik";
import TextAreaInput from "../Components/TextArea";
import { CommentAPI } from "../API";
import FloatNotification from "../Components/FloatNotification";
import Loading from "../Components/Loading";

const EditBlogComment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const commentId = new URLSearchParams(location.search).get("id");
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      description: "",
      blog_name: "",
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("Name is required"),
      email: yup.string().required("Email is required"),
      phone_number: yup.string().required("Phone Number is required"),
      blog_name: yup.string().required("Blog Name is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      updateBlogComment();
    },
  });

  const updateBlogComment = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${CommentAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };
  const getTestimonialById = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${CommentAPI}/get-by-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ _id: commentId }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setValues({ ...data.data });
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  useEffect(() => {
    if (!ignore && commentId) {
      getTestimonialById();
    }

    return () => {
      ignore = true;
    };
  }, [commentId]);
  return (
    <div className="bg-[#F9FAFB] px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 lg:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Edit Comment
          </h3>
        </div>
        <Button
          text="Back"
          onClick={() => {
            navigate("/super_admin/dashboard/blog-comment");
          }}
        />
      </div>
      {loading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <div className="w-20">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="mt-10 flex flex-col gap-5">
          <div className="flex w-full gap-5">
            <InputText
              type="text"
              id="full_name"
              label="Full Name"
              name="full_name"
              placeholder="Full Name"
              value={values?.full_name ?? ""}
              onChange={handleChange}
              error={errors.full_name && touched.full_name}
              errorText={errors.full_name}
            />
            <InputText
              type="text"
              id="email"
              label="Email"
              name="email"
              placeholder="Email"
              value={values?.email ?? ""}
              onChange={handleChange}
              error={errors.email && touched.email ? true : false}
              errorText={errors.email}
            />
          </div>
          <div className="flex w-full gap-5">
            <InputText
              type="text"
              id="phone_number"
              label="Phone Number"
              name="phone_number"
              placeholder="Phone Number"
              value={values?.phone_number ?? ""}
              onChange={handleChange}
              error={errors.phone_number && touched.phone_number ? true : false}
              errorText={errors.phone_number}
            />
            <InputText
              type="text"
              id="blog_name"
              label="Blog Name"
              name="blog_name"
              placeholder="Blog Name"
              value={values?.blog_name ?? ""}
              onChange={handleChange}
              error={errors.blog_name && touched.blog_name ? true : false}
              errorText={errors.blog_name}
            />
          </div>
          <TextAreaInput
            type="text"
            id="description"
            label="Description"
            name="description"
            placeholder="Description"
            value={values?.description ?? ""}
            onChange={handleChange}
            error={errors.description && touched.description}
            errorText={errors.description}
          />
        </div>
      )}
      <div className="flex mt-5 gap-5 ">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          className="text-center w-full bg-blue-400 gap-2 items-center hover:bg-blue-500 text-white font-bold py-[9px] px-4 rounded"
        >
          Save
        </button>
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="text-center w-full border border-blue-400 gap-2 items-center text-blue-500 font-bold py-[9px] px-4 rounded"
        >
          Cancel
        </button>
      </div>
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default EditBlogComment;

import {
  IconBabyCarriage,
  IconBed,
  IconCurrentLocation,
  IconCurrentLocationOff,
  IconMapPin,
  IconUser,
} from "@tabler/icons-react";
import React, { useState } from "react";
import HeroSelection from "./HeroSelection";
import HeroDatePicker from "./HeroDatePicker";
import { ContextStore } from "../context/WebContext";
import { useNavigate } from "react-router-dom";
import HeroInput from "./HeroInput";
const HotelForm = ({ countryOptions }) => {
  const navigate = useNavigate();
  const [showOption, setShowOption] = useState(false);
  const [selectionType, setSelectionType] = useState("");
  const { formDetails, setFormDetails } = ContextStore();
  const roomOption = new Array(20).fill().map((e, i) => {
    return { value: i + 1, label: i + 1 };
  });
  const adultsOption = new Array(40).fill().map((e, i) => {
    return { value: i + 1, label: i + 1 };
  });
  const childrenOption = new Array(40).fill().map((e, i) => {
    return { value: i, label: i };
  });
  return (
    <div>
      <div className="grid justify-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-10 xl:gap-12 px-10 2xl:justify-items-start py-5">
        <HeroInput
          Icon={IconMapPin}
          type="text"
          id="destination_from"
          label="Destination *"
          name="destination_from"
          placeholder="Destination"
          value={formDetails.destination_from ?? ""}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              destination_from: e.target.value,
            });
          }}
        />
        <HeroDatePicker
          name="Check In"
          placeholder={"Check In"}
          Icon={IconCurrentLocation}
          value={formDetails?.check_in}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              check_in: new Date(e[0]).toISOString(),
            });
          }}
          label="Check In *"
        />
        <HeroDatePicker
          name="Check Out"
          placeholder={"Check out"}
          Icon={IconCurrentLocationOff}
          value={formDetails?.check_out}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              check_out: new Date(e[0]).toISOString(),
            });
          }}
          label="Check Out *"
        />
        <HeroSelection
          Icon={IconBed}
          showOption={showOption}
          label="Rooms *"
          option={roomOption}
          value={roomOption?.find((c) => c.value === +formDetails.room)}
          onChange={(e) => {
            setFormDetails({ ...formDetails, room: e.value.toString() });
          }}
        />
        <HeroSelection
          Icon={IconUser}
          showOption={showOption}
          label="Adults *"
          option={adultsOption}
          value={adultsOption?.find((c) => c.value === +formDetails.adult)}
          onChange={(e) => {
            setFormDetails({ ...formDetails, adult: e.value.toString() });
          }}
        />
        <HeroSelection
          Icon={IconBabyCarriage}
          showOption={showOption}
          label="Children *"
          option={childrenOption}
          value={childrenOption?.find((c) => c.value === +formDetails.children)}
          onChange={(e) => {
            setFormDetails({ ...formDetails, children: e.value.toString() });
          }}
        />
      </div>
    </div>
  );
};

export default HotelForm;

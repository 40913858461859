import React, { useRef, useState } from "react";
import PackageHero from "../../Components/PackageHero";
import { IconStarFilled } from "@tabler/icons-react";
import Booking from "../../Components/Booking";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MDInput from "../../Components/MDInput";
import LazyImage from "../../Components/LazyImage";
import Slider from "react-slick/lib/slider";
import Model from "../../Components/Model";
const Information = ({ content }) => {
  const slider = useRef(null);
  const [showModel, setShowModel] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const destinations = [
    { name: "destination", value: content?.information?.destination_country },
    { name: "departure", value: content?.information?.departure },
    { name: "departure time", value: content?.information?.departure_time },
    { name: "return time", value: content?.information?.return_time },
    { name: "dress code", value: content?.information?.dress_code },
    {
      name: "included",
      value: content?.information?.what_include,
    },
  ];
  const certificateSetting = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <div>
      <PackageHero
        name={content?.information?.destination_name}
        banner={content?.banner_img}
      >
        <div className="my-10 mx-5 md:mx-16 flex flex-col xl:flex-row gap-10">
          <div className="w-full">
            <div className="flex flex-col md:flex-row items-center gap-4">
              <h1 className="text-5xl font-semibold font-poppins">
                {content?.information?.destination_name}
              </h1>
            </div>
            <div className="flex gap-5 mt-6">
              <div className="flex items-center gap-2">
                {content?.information?.review &&
                  new Array(+content?.information?.review)
                    .fill()
                    .map((item, index) => (
                      <IconStarFilled
                        key={index}
                        className="h-6 w-6 text-orange-400"
                      />
                    ))}
              </div>
              <div className="font-poppins text-gray-400">
                ({content?.information?.review} Review)
              </div>
            </div>
            <div className="font-poppins mt-8 text-justify">
              <MDInput value={content?.information?.description} viewOnly />
            </div>
            <div className="flex gap-3 mt-8">
              <div className="bg-[#FD6469] flex gap-2 px-2 py-1 rounded items-center text-white">
                <CalendarIcon className="h-5 w-5 text-white" />{" "}
                {content?.information?.days}D / {content?.information?.night}N
              </div>
              <div className="bg-[#FD6469] flex gap-2 px-2 py-1 rounded items-center text-white">
                <UserIcon className="h-5 w-5 text-white" />{" "}
                {content?.information?.age}+ Age
              </div>
            </div>
            <div className="mt-8 space-y-10">
              {destinations.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <div className="font-poppins font-bold capitalize text-sm w-full">
                    {item.name}
                  </div>
                  {typeof item.value === "string" && (
                    <div className="text-left capitalize font-poppins text-sm w-full">
                      {item.value}
                    </div>
                  )}
                  {Array.isArray(item.value) && (
                    <ul className="grid grid-cols-1 md:grid-cols-2 w-full list-disc">
                      {item.value.map((i, ind) => (
                        <li key={ind}>{i}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div>
            <Booking destination={content?.information?.destination_name} />
          </div>
        </div>
        <div className="my-10 md:mx-6 space-y-5">
          <div className="px-10 flex justify-between w-full">
            <h1 className="font-bold font-poppins text-xl">From Our Gallery</h1>
            <div className="">
              <button
                onClick={() => slider?.current?.slickPrev()}
                className="bg-gray-100 rounded-tl-full hover:text-[#FD6469] transition-all duration-200 rounded-bl-full pl-3 pr-1 py-2 hover:shadow-xl"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => slider?.current?.slickNext()}
                className="bg-gray-100 rounded-tr-full hover:text-[#FD6469] transition-all duration-200 rounded-br-full pr-3 pl-1 py-2 hover:shadow-xl"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          {content?.gallery?.length > 0 && (
            <Slider
              ref={slider}
              className="px-10 flex justify-center items-center"
              {...certificateSetting}
            >
              {content?.gallery?.map((item, ind) => (
                <div key={ind} className="w-full relative cursor-pointer">
                  <LazyImage
                    onClick={() => {
                      setShowModel(true);
                      setSelectedImg(item);
                    }}
                    src={item}
                    className="h-52 w-52 object-cover object-center rounded-lg"
                    alt=""
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
        <Model setShowModel={setShowModel} showModel={showModel}>
          <div
            className="cursor-pointer flex justify-between items-center mb-5"
            onClick={() => {
              setShowModel(false);
            }}
          >
            <div className="font-semibold">
              {content?.information?.destination_name}
            </div>
            <XMarkIcon className="h-5 w-5 text-red-500" />
          </div>
          <img
            src={selectedImg}
            className="w-full h-[50vh] object-contain object-center"
          />
        </Model>
      </PackageHero>
    </div>
  );
};

export default Information;

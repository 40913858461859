import React from "react";
import watchVideo from "../images/watch-video.png";
import Heading from "./Heading";
import LazyImage from "./LazyImage";

const WatchVideo = ({ pageContent }) => {
  return (
    <section className="mt-10 md:mt-20">
      <div className="mx-5 md:mx-16">
        <div className="flex flex-wrap lg:flex-nowrap md:gap-10 items-center mx-auto 2xl:w-5/6">
          <LazyImage
            className="mx-auto md:w-2/3 lg:w-5/6 xl:w-3/4"
            src={watchVideo}
            alt={"Watch Video"}
          />
          <div className="flex flex-col mt-10 md:mt-0 items-center lg:items-start text-left lg:flex-grow lg:w-2/3 xl:w-2/4 md:mb-0 xl:mt-0">
            <div className="flex gap-4 mb-4 items-center xl:text-start">
              <Heading heading={"Beauty on the Earth"} />
            </div>
            <h1 className="font-poppins text-center lg:text-start text-3xl md:text-4xl xl:text-5xl leading-10 lg:!leading-snug xl:!leading-[60px] font-bold text-[#1A093F] capitalize 2xl:text-5xl lg:w-3/4">
              The Happiest Place
            </h1>
            <p className="capitalize text-sm text-center lg:text-justify text-gray-500 mb-4 mt-4 font-normal xl:text-lg">
              Thailand, often called the Land of Smiles, captivates with its
              rich cultural heritage and stunning natural beauty. From bustling
              markets and ancient temples to pristine beaches and vibrant street
              life, it offers a tapestry of experiences that enchant and
              inspire.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WatchVideo;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ContextStore } from "../context/WebContext";
import Model from "./Model";
import InputText from "./InputText";
import TextAreaInput from "./TextArea";
import { LeadAPI } from "../API";
import FloatNotification from "./FloatNotification";

const QueryModel = ({ setShowModel, showModel, setShowNotification }) => {
  const phoneRegExp = /^(\+?91|0)?[6789]\d{9}$/;
  const { setLoading, loading, formDetails } = ContextStore();
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("Full name is required"),
      email: yup
        .string()
        .email("Please enter valid email")
        .required("Email name must be required"),
      phone_number: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone Numbwe is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      addLead();
    },
  });

  const addLead = () => {
    setLoading(true);
    fetch(`${LeadAPI}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formDetails,
        full_name: values.full_name,
        phone_number: values.phone_number,
        email: values.email,
        description: values.description,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          resetForm();
          setShowNotification({
            show: true,
            message: (
              <div>
                Your message has been received, and we'll get back to you as
                soon as possible. Have a great day!
              </div>
            ),
            title: "Add",
            status: "success",
          });
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
      });
  };
  return (
    <div>
      <Model
        setShowModel={setShowModel}
        title={"Query Now"}
        showModel={showModel}
        handleClose={() => {
          resetForm();
          setShowModel(false);
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="md:space-y-10 py-8">
            <div>
              <InputText
                type="text"
                id="full_name"
                label="Full Name"
                name="full_name"
                placeholder="Title"
                value={values?.full_name ?? ""}
                onChange={handleChange}
                error={errors.full_name && touched.full_name}
                errorText={errors.full_name}
              />
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              <InputText
                type="text"
                id="email"
                label="Email"
                name="email"
                placeholder="Email"
                value={values?.email ?? ""}
                onChange={handleChange}
                error={errors.email && touched.email}
                errorText={errors.email}
              />
              <InputText
                type="text"
                id="phone_number"
                label="Phone Number"
                name="phone_number"
                placeholder="Phone Number"
                value={values?.phone_number ?? ""}
                onChange={handleChange}
                error={errors.phone_number && touched.phone_number}
                errorText={errors.phone_number}
              />
            </div>
            <div>
              <TextAreaInput
                type="text"
                id="description"
                label="Description"
                name="description"
                placeholder="Description"
                value={values?.description ?? ""}
                onChange={handleChange}
                error={errors.description && touched.description}
                errorText={errors.description}
              />
            </div>
            <div className="w-full flex justify-center">
              <button
                type="submit"
                className="bg-[#FD5056] shadow-lg shadow-[#FD5056] hover:text-[#FD5056] transition-all duration-300 hover:bg-white text-white rounded-full px-10 py-3 font-medium font-rubik"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Model>
    </div>
  );
};

export default QueryModel;

import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_red.css";
const DatePicker = ({ label, name, isTime, onChange, value, placeholder }) => {
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="block text-gray-800 font-bold">
          {label}
        </label>
      )}
      <Flatpickr
        options={isTime ? { enableTime: true, noCalendar: true } : {}}
        value={value}
        className="date_picker w-full border border-gray-300 focus:border-blue-600 py-1.5 px-3 rounded mt-2 outline-none focus:ring-blue-600 :ring-blue-600"
        placeholder={placeholder}
        onChange={(date) => {
          onChange(date);
        }}
      />
    </div>
  );
};

export default DatePicker;

import React from "react";
import wildLifeImg from "../images/wildlife.png";
import paraglidingImg from "../images/paragliding.png";
import adventureImg from "../images/adventure.png";
import beachImg from "../images/beach.png";
import { useNavigate } from "react-router-dom";
const Contact = ({ bgImg }) => {
  const cards = [
    { name: "wildlife tours", img: wildLifeImg },
    { name: "paragliding tours", img: paraglidingImg },
    { name: "adventure tours", img: adventureImg },
    { name: "beaches tours", img: beachImg },
  ];
  const navigate = useNavigate();
  return (
    <div className="mt-20">
      <section
        className={`flex justify-center relative ${bgImg} bg-cover bg-center bg-no-repeat w-full h-[800px] lg:h-[600px] 2xl:h-[800px]`}
      >
        <div className="flex justify-center gap-10 lg:gap-0 lg:justify-between flex-col lg:flex-row items-center relative md:mx-auto px-5 md:px-10 lg:px-20 w-full bg-black/40">
          <div className="lg:text-left space-y-10 w-auto lg:w-2/4 xl:w-auto">
            <h1 className="font-lobster text-center lg:text-left text-white text-2xl capitalize">
              are you ready to travel?
            </h1>

            <p className="mt-4 w-ful xl:w-3/5 text-center lg:text-left font-rubik text-white text-4xl capitalize font-semibold">
              Moj ke Saudagar is a world leading online tour booking platform
            </p>

            <div className="mt-8 flex justify-center lg:justify-start flex-wrap gap-4 text-center">
              <button
                onClick={() => {
                  navigate("/contact-us");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="capitalize border border-[#FD6469] px-5 py-2 rounded-lg text-white font-semibold shadow-lg shadow-[#FD6469]/40"
              >
                contact us
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 md:gap-8">
            {cards.map((item, ind) => (
              <div
                key={ind}
                className="h-36 w-36 md:h-44 md:w-44 bg-white rounded-xl flex flex-col gap-3 justify-center items-center"
              >
                <img src={item.img} className="h-12 w-12 md:h-14 md:w-14" />
                <p className="px-8 font-rubik text-[#1A093F] font-semibold text-xl text-center capitalize">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;

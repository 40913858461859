import React from "react";
import whatSay from "../images/what-say.png";
import { IconStarFilled } from "@tabler/icons-react";
import whatSayImg from "../images/what-say.png";
import Heading from "./Heading";
import LazyImage from "./LazyImage";

const WhatSay = ({ pageContent }) => {
  return (
    <div>
      <section className="mt-10 md:mt-20">
        <div className="mx-5 md:mx-16">
          <div className="flex flex-wrap items-center mx-auto 2xl:w-5/6">
            <div className="flex flex-col items-center md:items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 md:mb-0 xl:mt-0">
              <div className="flex gap-4 mb-4 lg:mb-8 items-center xl:text-start">
                <Heading heading={"Discover, Experience, Wander."} />
              </div>
              <h1 className="font-poppins text-center md:text-start text-3xl md:text-4xl xl:text-5xl leading-10 md:!leading-[50px] font-bold text-[#1A093F] capitalize 2xl:text-5xl xl:w-3/4 2xl:w-4/5">
                Explore, embrace adventure, make memories.
              </h1>
              <p className="capitalize text-sm lg:text-lg text-center md:text-justify text-gray-500 mb-4 lg:mb-8 mt-4 lg:mt-8 font-normal xl:w-3/4 2xl:w-4/5 xl:text-lg">
                Immerse yourself in the rich tapestry of global cultures and
                explore breathtaking landscapes that span from rugged mountains
                to pristine coastlines. Each destination offers a unique blend
                of history, tradition, and natural beauty, inviting you to
                discover new perspectives and create lasting memories.
              </p>
            </div>
            <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
              {/* <LazyImage
                className="object-cover object-center mx-auto w-full md:w-4/6 lg:w-full"
                alt="hero"
                src={pageContent?.explore_world_img}
              /> */}
              <LazyImage
                className="object-cover object-center mx-auto w-full md:w-4/6 lg:w-full"
                alt="hero"
                src={whatSayImg}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatSay;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Components/Button";
import { FavouriteDestinationAPI } from "../API";
import AdminDestinationForm from "../Components/AdminDestinationForm";

const AddFavouriteDestination = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const packageId = new URLSearchParams(location.search).get("id");
  let ignore = false;
  const addFavouriteDestination = (values, resetForm, setShowNotification) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${FavouriteDestinationAPI}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          information: {
            ...values.information,
            what_include: values.information.what_include.map(
              (item) => item.value
            ),
          },
          banner_img: values.banner_img,
          outer_banner: values.outer_banner,
          review: values.review,
          gallery: values.gallery.map((item) => item.gallery_img),
          tourplan: values.tourplan,
          include: values.include,
          exclude: values.exclude,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };

  const updateFavouriteDestination = (
    values,
    resetForm,
    setShowNotification
  ) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(`${FavouriteDestinationAPI}/update `, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          _id: packageId,
          information: {
            ...values.information,
            what_include: values.information.what_include.map(
              (item) => item.value
            ),
          },
          banner_img: values.banner_img,
          outer_banner: values.outer_banner,
          review: values.review,
          gallery: values.gallery.map((item) => item.gallery_img),
          tourplan: values.tourplan,
          include: values.include,
          exclude: values.exclude,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === "success") {
            resetForm();
            navigate(-1);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="bg-[#F9FAFB]  px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Add Favourite Destination
          </h3>
        </div>
        <Button
          text="Back"
          onClick={() => {
            navigate("/super_admin/dashboard/favourite-destination");
          }}
        />
      </div>
      <AdminDestinationForm
        addDestination={addFavouriteDestination}
        updateDestination={updateFavouriteDestination}
        packageId={packageId}
        API={FavouriteDestinationAPI}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default AddFavouriteDestination;

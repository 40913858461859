import React, { useEffect, useRef, useState } from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";

const MDInput = ({
  value,
  onChange,
  viewOnly,
  id,
  label,
  error,
  errorText,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const revertElementStyle = (elem) => {
    if (["ul", "li", "ol", "a"].includes(elem.tagName.toLowerCase())) {
      elem.style.all = "revert";
    }
    let findChild = elem.children;
    for (let index = 0; index < findChild.length; index++) {
      const element = findChild[index];
      revertElementStyle(element);
    }
  };
  useEffect(() => {
    if (ref.current) {
      revertElementStyle(ref.current);
    }
  }, [value]);
  useEffect(() => {
    const { offsetWidth } = ref.current;
    setWidth(offsetWidth);
  }, []);
  return (
    <div data-color-mode="light" ref={ref}>
      {label && (
        <label className="block text-gray-800 font-bold">{label}</label>
      )}
      {viewOnly ? (
        <MarkdownEditor.Markdown source={value} />
      ) : (
        <MarkdownEditor
          height="150px"
          value={value}
          onChange={onChange}
          className="mt-2"
          id={id}
          style={{ width: `${width}px` }}
        />
      )}
      {error && <p className="text-red-500 text-sm">{errorText}</p>}
    </div>
  );
};

export default MDInput;

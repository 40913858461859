import React, { useEffect, useState } from "react";
import HeroSection from "../../Components/HeroSection";
import Favourite from "../../Components/Favourite";
import Experience from "../../Components/Experience";
import TravelGallery from "../../Components/TravelGallery";
import Contact from "../../Components/Contact";
import Testimonial from "../../Components/Testimonial";
import Articles from "../../Components/Articles";
import flightIcn from "../../images/flight.png";
import hotelIcn from "../../images/hotel.png";
import packageIcn from "../../images/package.png";
import trainIcn from "../../images/train.png";
import bussIcn from "../../images/buss.png";
import cabsIcn from "../../images/cabs.png";
import CruiseIcn from "../../images/ship.png";
import visaIcn from "../../images/forex.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import insuranceIcn from "../../images/insurence.png";
import {
  BlogAPI,
  DestinationAPI,
  FavouriteDestinationAPI,
  HomePageAPI,
  TestimonialAPI,
} from "../../API";
import { ContextStore } from "../../context/WebContext";
import WebLoading from "../../Components/WebLoading";

const Home = () => {
  
  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-Y599C6263F');

  }, []);
  const { loading, setLoading } = ContextStore();
  const [homePageContents, setHomePageContents] = useState({});
  const [selectedOption, setSelectedOption] = useState(
    window.innerWidth < 768 ? "" : "hotel"
  );
  const [countryOptions, setCountryOptions] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [favouriteDestinationData, setFavouriteDestinationData] = useState([]);
  let ignore = false;
  const queryOption = [
    { name: "Hotel", icon: hotelIcn, selection_name: "hotel" },
    { name: "Flight", icon: flightIcn, selection_name: "flight" },
    { name: "Visa", icon: insuranceIcn, selection_name: "visa" },
    { name: "Bus", icon: bussIcn, selection_name: "bus" },
    { name: "Train", icon: trainIcn, selection_name: "train" },
    { name: "Cruise", icon: CruiseIcn, selection_name: "Cruise" },
    { name: "Cab", icon: cabsIcn, selection_name: "cab" },
    { name: "Package", icon: packageIcn, selection_name: "package" },
  ];
  const getHomePageDetails = async () => {
    setLoading(true);
    await fetch(HomePageAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setHomePageContents(data.data);
        }
        setLoading(false);
      });
  };

  const getTestimonial = async () => {
    setLoading(true);
    await fetch(TestimonialAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setTestimonialData(data?.data);
        }
      });
  };
  const getBlogs = async () => {
    setLoading(true);
    await fetch(BlogAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setBlogsData(data?.data);
        }
      });
  };
  const getFavouriteDestination = async () => {
    setLoading(true);
    await fetch(FavouriteDestinationAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setFavouriteDestinationData(data?.data);
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      getHomePageDetails();
      getTestimonial();
      getBlogs();
      getFavouriteDestination();
    }

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      <head>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <title>
          Moj ke Saudagar: Top Travel Agency for Domestic & International
          </title>
          <meta
            name="description"
            content="Moj ke Saudagar is your trusted travel partner, specializing in customized domestic and international tours. Explore top destinations with our expert travel agents and seamless tour organization services."
          />
          <meta
            name="keywords"
            content="travel agency, tour organizer, domestic tours, international tours, travel services, Moj ke Saudagar"
          />
          <meta name="author" content="Moj ke Saudagar" />
          <meta
            name="google-site-verification"
            content="ESWm-Q7TLOMv5gFXEYqTbhYjMXHfIvjJxN-GphLWkNY"
          />
          <link rel="canonical" href="https://mojkesaudagar.com/"></link>
        </Helmet>
      </HelmetProvider>
      </head>
      {loading ? (
        <WebLoading />
      ) : (
        <>
          <HeroSection
            content={homePageContents}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            queryOption={queryOption}
            countryOptions={countryOptions}
            formLoading={formLoading}
          />
          <Favourite favouriteDestinationData={favouriteDestinationData} />
          <Experience content={homePageContents} />
          <TravelGallery />
          <Contact bgImg="bg-[url(/public/home-contect.png)]" />
          <Testimonial testimonialData={testimonialData} />
          <Articles blogsData={blogsData} />
        </>
      )}
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import Button from "../Components/Button";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import DeleteModel from "../Components/DeleteModel";
import FloatNotification from "../Components/FloatNotification";
import { TestimonialAPI } from "../API";
import Pagination from "../Components/Pagination";
import Loading from "../Components/Loading";

const AdminTestimonial = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [testimoniId, setTestimoniId] = useState("");
  const [modelLoading, setModelLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  let ignore = false;
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  const getAllTestimonial = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setLoading(true);
      fetch(TestimonialAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ page: page }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data?.status === "success") {
            setTestimonialData(data.data);
            setCount(data.counts);
            setPageCount(data.page);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
        });
    }
  };
  const deleteTestimonial = () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setModelLoading(true);
      fetch(`${TestimonialAPI}/delete `, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          id: testimoniId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            getAllTestimonial();
            setDeleteModal(false);
          } else {
            setShowNotification({
              show: true,
              message: <div>{data.error}</div>,
              title: "Backend Error",
              status: "failed",
            });
          }
          setModelLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!ignore) {
      getAllTestimonial();
    }
    return () => {
      ignore = true;
    };
  }, [page]);
  return (
    <div className=" px-4 py-4">
      <div className="md:flex items-center justify-between mb-4 gap-3">
        <div className="mb-4 md:mb-0">
          <h3 className="font-bold text-2xl text-indigo-900 font-poppins">
            Testimonial
          </h3>
        </div>
        <Button
          Icon={PlusIcon}
          text="Add Testimonial"
          onClick={() => {
            navigate("/super_admin/dashboard/testimonial/add");
          }}
        />
      </div>
      <div className="py-4">
        <div className="inline-block w-full shadow rounded-lg overflow-x-auto destination-nav">
          <table className="w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  Blog Name
                </th>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  User Name
                </th>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  Description
                </th>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  User Role
                </th>
                <th className="px-5 text-nowrap py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600 capitalize">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} className="py-5">
                    <div className="w-full flex justify-center">
                      <div className="w-20">
                        <Loading />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              ) : testimonialData.length > 0 ? (
                testimonialData.map((testimoni, index) => (
                  <tr key={index}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-10 h-10">
                          <img
                            className="w-full h-full rounded-full"
                            src={testimoni.user_img}
                            alt={testimoni.user_name}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {testimoni.user_name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 break-words text-wrap capitalize">
                        {testimoni.user_name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 line-clamp-1 w-20 break-words text-wrap capitalize">
                        {testimoni.description}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 line-clamp-2 break-words text-wrap capitalize">
                        {testimoni.role}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center gap-5">
                        <button>
                          <PencilIcon
                            onClick={() => {
                              navigate(
                                `/super_admin/dashboard/testimonial/edit?id=${testimoni._id}`
                              );
                            }}
                            className="h-5 w-5 text-blue-500"
                          />
                        </button>
                        <button>
                          <TrashIcon
                            onClick={() => {
                              setTestimoniId(testimoni._id);
                              setDeleteModal(true);
                            }}
                            className="h-5 w-5 text-red-500"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="px-5 py-5  bg-white text-sm">
                    <div className="w-full text-gray-900 capitalize flex justify-center">
                      No Data Found In Testimonial
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            count={count}
            page={page}
            pageCount={pageCount}
            setPage={setPage}
            data={testimonialData}
          />
        </div>
      </div>
      <DeleteModel
        handleDelete={deleteTestimonial}
        setShowModal={setDeleteModal}
        title={"Delete Testimonial"}
        showModal={deleteModal}
        loading={modelLoading}
      />
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default AdminTestimonial;

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const LazyImage = ({ alt, src, className, ...props }) => {
  return (
    <div>
      <img
        alt={alt}
        src={src}
        className={className}
        loading="lazy"
        {...props}
      />
    </div>
  );
};

export default LazyImage;

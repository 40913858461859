import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BlogAPI, CommentAPI } from "../../API";
import AboutHero from "../../Components/AboutHero";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import TextAreaInput from "../../Components/TextArea";
import InputText from "../../Components/InputText";
import { useFormik } from "formik";
import * as yup from "yup";
import FloatNotification from "../../Components/FloatNotification";
import { ContextStore } from "../../context/WebContext";
import Loading from "../../Components/Loading";
import WebLoading from "../../Components/WebLoading";
import MDInput from "../../Components/MDInput";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BlogDetailBanner from "../../images/destination-hero.jpg";
import LazyImage from "../../Components/LazyImage";

const BlogDetails = () => {
  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-Y599C6263F");
  }, []);

  const { setLoading, loading } = ContextStore();
  const location = useLocation();
  let selectedBlogId = location?.state?.id;
  const navigate = useNavigate();
  const [blogDetail, setBlogDetail] = useState({});
  const [blogsData, setBlogsData] = useState();
  const [showNotification, setShowNotification] = useState({
    show: false,
    status: "",
    title: "",
    message: <></>,
  });
  let ignore = false;
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone_number: "",
      description: "",
    },
    validationSchema: yup.object().shape({
      full_name: yup.string().required("Full name is required"),
      email: yup.string().required("Email name is required"),
      phone_number: yup.string().required("Phone Number role is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: () => {
      addComment();
    },
  });
  const getBlogDetails = async () => {
    setLoading(true);
    await fetch(`${BlogAPI}/get-by-id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...(location?.state?.id
          ? { _id: selectedBlogId }
          : { blogName: selectedBlogId }),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setBlogDetail(data?.data);
        }
      });
  };
  const getAllBlogs = async () => {
    setLoading(true);
    await fetch(BlogAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ page: 1 }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setBlogsData(data?.data);
        }
      });
  };
  const addComment = () => {
    setLoading(true);
    fetch(`${CommentAPI}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        full_name: values.full_name,
        phone_number: values.phone_number,
        email: values.email,
        description: values.description,
        blog_name: blogDetail?.blog_name,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          resetForm();
          setShowNotification({
            show: true,
            message: <div>Your Comment Send Successfully</div>,
            title: "Add",
            status: "success",
          });
        } else {
          setShowNotification({
            show: true,
            message: <div>{data.error}</div>,
            title: "Backend Error",
            status: "failed",
          });
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      location?.state?.id
        ? (selectedBlogId = location?.state?.id)
        : (selectedBlogId = location?.pathname
            ?.split("/")[3]
            ?.split("-")
            ?.join(" "));
    }
    return () => {
      ignore = true;
    };
  }, []);
  useEffect(() => {
    if (!ignore) {
      console.log(selectedBlogId);
      if (selectedBlogId) {
        getBlogDetails();
        getAllBlogs();
      } else {
        navigate("/blog");
      }
    }
    return () => {
      ignore = true;
    };
  }, [selectedBlogId]);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{blogDetail?.blog_name}</title>
        </Helmet>
      </HelmetProvider>
      {loading ? (
        <WebLoading />
      ) : (
        <>
          <AboutHero pageContent={blogDetail} imgSrc={BlogDetailBanner} />
          {/* <AboutHero pageContent={blogDetail} imgSrc={HeroBlogImg} /> */}
          <div className="w-full flex justify-center">
            <div className="flex flex-col xl:flex-row my-20 gap-10 2xl:gap-10 2xl:w-4/5 w-4/5 justify-center">
              <div className="w-full">
                <LazyImage
                  className="h-auto object-cover object-center rounded-2xl w-full"
                  src={blogDetail?.blog_img}
                  alt=""
                />
                <div className="mt-8 flex justify-between items-center">
                  <p className="capitalize text-lg font-semibold text-[#1A093F]">
                    by moj ke saudagar
                  </p>
                  <div className="flex items-center gap-3">
                    <CalendarDaysIcon className="h-6 w-6 text-[#FD6469]" />
                    <p className="text-lg">
                      {moment(blogDetail?.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
                <div className="mt-6 font-bold text-lg text-[#1A093F] capitalize">
                  {blogDetail?.blog_name}
                </div>
                <div
                  className="mt-6 text-sm capitalize text-justify"
                  style={{ whiteSpace: "pre-line" }}
                >
                  <MDInput value={blogDetail?.description} viewOnly />
                </div>
              </div>
              <div className="flex flex-col gap-10">
                <div className="xl:order-1 order-2 w-full overflow-y-scroll h-2/4 rounded-xl px-5 py-5 shadow-[0px_2px_10px_1px_rgba(0,0,0,0.3)] destination-nav">
                  <p className="capitalize text-xl font-bold text-[#1A093F]">
                    recent post
                  </p>
                  <div className="flex flex-col gap-8 mt-8">
                    {blogsData?.filter((i) => i._id !== selectedBlogId)
                      ?.length > 0 ? (
                      blogsData
                        ?.filter((i) => i._id !== selectedBlogId)
                        ?.map((blogs, ind) => (
                          <div key={ind} className="flex gap-5 items-center">
                            <LazyImage
                              className="h-24 w-36 object-cover object-center rounded-xl"
                              src={blogs?.blog_img}
                              alt=""
                            />
                            <div
                              onClick={() => {
                                window.scrollTo({ top: 0, behavior: "smooth" });
                                navigate(
                                  `/blog/blog-detail/${blogs?.blog_name
                                    .split(" ")
                                    .join("-")}`,
                                  { state: { id: blogs?._id } }
                                );
                              }}
                              className="cursor-pointer"
                            >
                              <p className="text-lg font-bold capitalize text-[#1A093F] line-clamp-2">
                                {blogs?.blog_name}
                              </p>
                              <p>
                                {moment(blogs?.createdAt).format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="w-full h-full flex justify-center items-center">
                        No Recent Blog Found
                      </div>
                    )}
                  </div>
                </div>
                <div className="xl:order-2 order-1 w-full bg-white rounded-xl shadow-[0_0_20px_1px_rgba(0,0,0,0.3)] space-y-5 md:space-y-10 py-8 px-5 ">
                  <p className="text-left text-4xl font-bold text-[#1A093F] capitalize">
                    leave a reply
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <InputText
                        type="text"
                        id="full_name"
                        label="Full Name"
                        name="full_name"
                        placeholder="Title"
                        value={values?.full_name ?? ""}
                        onChange={handleChange}
                        error={errors.full_name && touched.full_name}
                        errorText={errors.full_name}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row gap-5">
                      <InputText
                        type="text"
                        id="email"
                        label="Email"
                        name="email"
                        placeholder="Email"
                        value={values?.email ?? ""}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        errorText={errors.email}
                      />
                      <InputText
                        type="text"
                        id="phone_number"
                        label="Phone Number"
                        name="phone_number"
                        placeholder="Phone Number"
                        value={values?.phone_number ?? ""}
                        onChange={handleChange}
                        error={errors.phone_number && touched.phone_number}
                        errorText={errors.phone_number}
                      />
                    </div>
                    <div>
                      <TextAreaInput
                        type="text"
                        id="description"
                        label="Description"
                        name="description"
                        placeholder="Description"
                        value={values?.description ?? ""}
                        onChange={handleChange}
                        error={errors.description && touched.description}
                        errorText={errors.description}
                      />
                    </div>
                    <div className="w-full flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#FD5056] shadow-lg shadow-[#FD5056] hover:text-[#FD5056] transition-all duration-300 hover:bg-white text-white rounded-full px-10 py-3 font-medium font-rubik"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <FloatNotification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
      />
    </div>
  );
};

export default BlogDetails;

import {
  IconCalendarSmile,
  IconCalendarStats,
  IconClock,
  IconClockPin,
  IconLocation,
  IconMapPin,
  IconUser,
} from "@tabler/icons-react";
import React, { useState } from "react";
import HeroSelection from "./HeroSelection";
import HeroDatePicker from "./HeroDatePicker";
import { ContextStore } from "../context/WebContext";
import { useNavigate } from "react-router-dom";
import HeroInput from "./HeroInput";
const CabsForm = ({ countryOptions }) => {
  const navigate = useNavigate();
  const [showOption, setShowOption] = useState(false);
  const { formDetails, setFormDetails } = ContextStore();
  const adultsOption = new Array(40).fill().map((e, i) => {
    return { value: i + 1, label: i + 1 };
  });
  return (
    <div>
      <div className="grid justify-items-center md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-10 xl:gap-12 px-10 2xl:justify-items-start py-5">
        <HeroInput
          Icon={IconLocation}
          type="text"
          id="destination_from"
          label="Destination From *"
          name="destination_from"
          placeholder="Destination From"
          value={formDetails.destination_from ?? ""}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              destination_from: e.target.value,
            });
          }}
        />
        <HeroInput
          Icon={IconMapPin}
          type="text"
          id="destination_to"
          label="Destination To *"
          name="destination_to"
          placeholder="Destination To"
          value={formDetails.destination_to ?? ""}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              destination_to: e.target.value,
            });
          }}
        />
        <HeroDatePicker
          Icon={IconCalendarStats}
          label="Travel Date From *"
          placeholder={"Check In"}
          value={formDetails?.travel_from}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              travel_from: new Date(e[0]).toISOString(),
            });
          }}
        />
        <HeroDatePicker
          Icon={IconCalendarSmile}
          label="Travel Date To *"
          placeholder={"Check Out"}
          value={formDetails?.travel_to}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              travel_to: new Date(e[0]).toISOString(),
            });
          }}
        />
        <HeroDatePicker
          Icon={IconClock}
          label="Pickup Time *"
          placeholder={"Pickup Time"}
          value={formDetails?.pickup_time}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              pickup_time: new Date(e[0]).toTimeString(),
            });
          }}
        />
        <HeroDatePicker
          Icon={IconClockPin}
          label="Drop Time *"
          placeholder={"Drop Time"}
          value={formDetails?.drop_time}
          onChange={(e) => {
            setFormDetails({
              ...formDetails,
              drop_time: new Date(e[0]).toTimeString(),
            });
          }}
        />

        <HeroSelection
          Icon={IconUser}
          showOption={showOption}
          label="Adults *"
          option={adultsOption}
          value={adultsOption?.find((c) => c.value === +formDetails.adult)}
          onChange={(e) => {
            setFormDetails({ ...formDetails, adult: e.value.toString() });
          }}
        />
      </div>
    </div>
  );
};

export default CabsForm;

import React, { useState } from "react";
import PackageHero from "../../Components/PackageHero";
import cardImg from "../../images/cardimg.png";
import Booking from "../../Components/Booking";
import LazyImage from "../../Components/LazyImage";
import Model from "../../Components/Model";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Gallery = ({ content }) => {
  const [showModel, setShowModel] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  return (
    <div>
      <PackageHero
        name={content?.information?.destination_name}
        banner={content?.banner_img}
      >
        <div className="my-10 mx-5 md:mx-16 flex flex-col xl:flex-row gap-10">
          <div className="w-full">
            <h1 className="text-5xl font-semibold font-poppins capitalize">
              gallery
            </h1>
            <div className="grid justify-items-center grid-cols-2 xl:justify-items-start md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-8 gap-5 py-16">
              {content?.gallery?.map((item, ind) => (
                <LazyImage
                  onClick={() => {
                    setShowModel(true);
                    setSelectedImg(item);
                  }}
                  key={ind}
                  className="h-52 w-52 object-cover rounded-xl cursor-pointer"
                  src={item}
                  alt="gallery image"
                />
              ))}
            </div>
          </div>
          <div>
            <Booking destination={content?.information?.destination_name} />
          </div>
          <Model setShowModel={setShowModel} showModel={showModel}>
            <div
              className="cursor-pointer flex justify-between items-center mb-5"
              onClick={() => {
                setShowModel(false);
              }}
            >
              <div className="font-semibold">
                {content?.information?.destination_name}
              </div>
              <XMarkIcon className="h-5 w-5 text-red-500" />
            </div>
            <img
              src={selectedImg}
              className="w-full h-[50vh] object-contain object-center"
            />
          </Model>
        </div>
      </PackageHero>
    </div>
  );
};

export default Gallery;

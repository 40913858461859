import React, { useEffect, useState } from "react";
import AboutHero from "../../Components/AboutHero";
import AboutImg from "../../images/about-us.png";
import Heading from "../../Components/Heading";
import planeImg from "../../images/plane.png";
import planeVectorImg from "../../images/plane-vector.png";
import tourImg from "../../images/tour.png";
import globPlaneImg from "../../images/glob-plane.png";
import Contact from "../../Components/Contact";
import WhatSay from "../../Components/WhatSay";
import WatchVideo from "../../Components/WatchVideo";
import { AboutPageAPI } from "../../API";
import { ContextStore } from "../../context/WebContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LazyImage from "../../Components/LazyImage";
import bannerImg from "../../images/about-banner.jpg";
const AboutUs = () => {

  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-Y599C6263F');

  }, []);

  const aboutPageContents = {
    title: "We Take Care Of Your Trip",
    tag_line: "About Us",
    description:
      "Crafting unforgettable travel experiences worldwide with exceptional service and sustainability.",
  };
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <title>
            About Us - Moj ke Saudagar: Your Travel Experts for Domestic &
            International Tours
          </title>
          <meta
            name="description"
            content="Learn more about Moj ke Saudagar, your dedicated travel agency specializing in personalized domestic and international tours. Discover our mission, values, and the team committed to making your travel experiences extraordinary."
          />
          <meta
            name="keywords"
            content="About Moj ke Saudagar, travel agency, tour organizers, domestic tours, international tours, travel services, travel team"
          />
          <meta name="author" content="Moj ke Saudagar" />
          
          <link rel="canonical" href="https://mojkesaudagar.com/about"></link>
        </Helmet>
      </HelmetProvider>
      <AboutHero pageContent={aboutPageContents} imgSrc={bannerImg} />
      <section className="text-gray-700 body-font mt-10 md:mt-20 px-5 md:px-10 xl:px-12 2xl:px-0">
        <div className="px-5 mx-auto flex-col flex xl:flex-row">
          <div className="xl:w-1/2 flex justify-center w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <LazyImage
              alt="feature"
              className="object-cover object-center w-full "
              src={AboutImg}
            />
          </div>
          <div className="flex flex-col gap-10 flex-wrap lg:py-6 -mb-10 xl:w-1/2 lg:pl-12 xl:text-left text-center">
            <div className="flex gap-4 items-center justify-center xl:justify-start xl:text-start">
              <Heading heading={"about us"} />
            </div>
            <div className="w-full">
              <h1 className="font-poppins text-2xl md:text-4xl xl:text-[48px] leading-snug font-bold text-[#1A093F] capitalize 2xl:text-5xl">
                Pioneers in the
              </h1>
              <h1 className="flex justify-center xl:justify-start items-end gap-4 font-poppins text-2xl md:text-4xl xl:text-[48px] leading-snug font-bold text-[#1A093F] capitalize 2xl:text-5xl">
                tourism industry
                <img src={planeImg} className="mt-2 w-8 h-8 md:h-12 md:w-12 " />
              </h1>
            </div>
            <div className="text-sm text-black lg:text-lg capitalize text-justify">
              Step into a realm of unparalleled travel experiences with us, the
              original pioneers in the tourism world. We transform ordinary
              journeys into extraordinary adventures, tailored just for you.
            </div>
            <div className="flex lg:items-start items-center gap-5">
              <img alt="feature" className="h-10 w-10" src={planeVectorImg} />
              <div className="flex-grow">
                <h2 className="capitalize text-start text-gray-900 text-lg title-font font-bold mb-3">
                  book with confident
                </h2>
                <p className="leading-relaxed capitalize text-sm lg:text-lg text-justify">
                  Book with confidence and unlock unparalleled travel
                  experiences with us. Your journey is our priority, ensuring
                  every detail is perfectly tailored for your adventure.
                </p>
              </div>
            </div>
            <div className="flex lg:items-start  items-center gap-5">
              <img alt="feature" className="h-10 w-10" src={tourImg} />
              <div className="flex-grow">
                <h2 className="capitalize text-start text-gray-900 text-lg title-font font-bold mb-3">
                  freedom to discover, confidence to explore
                </h2>
                <p className="leading-relaxed text-justify capitalize text-sm lg:text-lg">
                  Embrace the freedom to discover new horizons and the
                  confidence to explore with our expert guidance. Your adventure
                  awaits, crafted with precision and passion.
                </p>
              </div>
            </div>
            <div className="flex lg:items-start items-center gap-5">
              <img alt="feature" className="h-10 w-10" src={globPlaneImg} />
              <div className="flex-grow">
                <h2 className="capitalize text-start text-gray-900 text-lg title-font font-bold mb-3">
                  dive into Culture
                </h2>
                <p className="leading-relaxed text-justify text-sm lg:text-lg capitalize">
                  Dive into culture and immerse yourself in the rich traditions
                  and vibrant heritage of your destination. Experience the world
                  like never before, with every journey offering a unique
                  cultural tapestry to explore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhatSay />
      <WatchVideo />
      <div className="mb-20">
        <Contact bgImg="bg-[url(/public/about-contact.png)]" />
      </div>
    </div>
  );
};

export default AboutUs;

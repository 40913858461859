import React from "react";
import AboutHero from "../../Components/AboutHero";
import Heading from "../../Components/Heading";
import { IconStarFilled } from "@tabler/icons-react";
import Contact from "../../Components/Contact";
import TravelGallery from "../../Components/TravelGallery";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../Components/LazyImage";
import logo from "../../images/logo.png";
const Destination = ({ tourListData }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mx-16 mt-20">
        <div>
          <div className="flex justify-center md:justify-start gap-4 items-center">
            <Heading heading={"top destination"} />
          </div>
          <h1 className="capitalize text-center md:text-start text-[#1A093F] text-3xl font-bold font-poppins mt-2">
            explore top destinations
          </h1>
        </div>
        <div className="mt-10 w-full h-full gap-6 grid-cols-1 lg:gap-10 2xl:grid-cols-5 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center">
          {tourListData.map((tour, index) => (
            <div
              key={index}
              className="flex flex-col items-center md:block rounded-xl"
            >
              <LazyImage
                className="h-52 w-52 object-cover rounded-xl"
                src={tour?.outer_banner ? tour?.outer_banner : logo}
                alt=""
              />
              <div
                onClick={() => {
                  navigate(
                    `/tour-list/${tour?.information?.destination_name
                      .split(" ")
                      .join("-")}`,
                    { state: { id: tour?._id, type: "des" } }
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="cursor-pointer p-2 flex flex-col md:block items-center"
              >
                <h2 className="font-bold w-4/6 font-poppins capitalize text-[#1A093F] text-sm mb-2 text-center md:text-start">
                  {tour?.information?.destination_title}
                </h2>
                <p className="text-sm text-gray-600 mb-2">
                  {tour?.information?.destination_name},{" "}
                  {tour?.information?.destination_country}
                </p>
                <div className="flex rounded-full gap-1 items-center w-14 justify-center px-2 bg-[#FFEED6]">
                  <IconStarFilled className="w-3 h-3 text-orange-400" />
                  <p className="text-sm font-semibold">
                    {tour?.information?.review}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Destination;

import React from "react";

const HeroInput = ({
  label,
  name,
  Icon,
  onChange,
  value,
  type,
  placeholder,
}) => {
  return (
    <div className="flex items-center gap-3">
      <Icon className="h-6 w-6 text-[#FD6469]" />
      <div className="w-44">
        <label className="capitalize block text-sm text-gray-500 mb-[3px]">
          {label}
        </label>
        <input
          className="textInput border-b pb-1 border-b-red-500 focus-visible:ring-0 focus-visible:outline-none bg-transparent"
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default HeroInput;

import React, { useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import LazyImage from "./LazyImage";
const Favourite = ({ favouriteDestinationData }) => {
  const navigate = useNavigate();
  const slider = useRef(null);
  const certificateSetting = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className="mt-20 w-full">
      <div className="md:mx-10 space-y-4 lg:mx-16 rounded-lg md:py-24 bg-[url(/public/map.png)] bg-center bg-cover md:bg-contain bg-no-repeat">
        <div className="flex justify-between w-full px-10">
          <div className="text-wrap break-words w-32 md:w-auto capitalize text-start text-xl md:text-3xl text-[#1A093F] font-bold ">
            favourite destinations
          </div>
          <div className="">
            <button
              onClick={() => slider?.current?.slickPrev()}
              className="bg-gray-100 rounded-tl-full hover:text-[#FD6469] transition-all duration-200 rounded-bl-full pl-3 pr-1 py-2 hover:shadow-xl"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button
              onClick={() => slider?.current?.slickNext()}
              className="bg-gray-100 rounded-tr-full hover:text-[#FD6469] transition-all duration-200 rounded-br-full pr-3 pl-1 py-2 hover:shadow-xl"
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        {favouriteDestinationData.length > 0 && (
          <Slider
            ref={slider}
            className="flex justify-center items-center px-10"
            {...certificateSetting}
          >
            {favouriteDestinationData.map((destinations, index) => (
              <div
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(
                    `/favourite-destination/${destinations?.information?.destination_name
                      .split(" ")
                      .join("-")}`,
                    { state: { id: destinations?._id, type: "fav" } }
                  );
                }}
                key={index}
                className="w-full relative cursor-pointer"
              >
                <LazyImage
                  src={destinations?.outer_banner}
                  className="h-56 w-36 object-cover object-center rounded-lg"
                  alt=""
                />
                <p className="text-xl font-semibold absolute bottom-2 left-3 text-white">
                  {destinations?.information?.destination_name}
                </p>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default Favourite;

import React from "react";
import cardImg from "../images/card-img.png";
import Heading from "./Heading";
import { useNavigate } from "react-router-dom";
import LazyImage from "./LazyImage";
import { ContextStore } from "../context/WebContext";
const Articles = ({ blogsData }) => {
  const navigate = useNavigate();
  return (
    <div className="mx-10 md:mx-16 mb-10">
      <div className="flex gap-4 items-center justify-center">
        <Heading heading={"articles"} />
      </div>
      <div className="capitalize text-center text-4xl text-[#1A093F] mt-2 font-bold w-full">
        featured blog posts
      </div>
      <div className="w-full grid justify-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-20">
        {blogsData?.map((blog, index) => (
          <div
            key={index}
            className="max-w-sm rounded-xl overflow-hidden shadow-lg space-y-4"
          >
            <LazyImage
              className="w-full"
              src={blog?.blog_outer_img}
              alt={blog?.blog_name}
            />
            <div className="px-6 space-y-5">
              <div
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate(
                    `/blog/blog-detail/${blog?.blog_name.split(" ").join("-")}`,
                    { state: { id: blog?._id } }
                  );
                }}
                className="font-bold cursor-pointer text-lg text-[#1A093F] capitalize"
              >
                {blog?.blog_name}
              </div>
              <p className="text-gray-700 line-clamp-2">{blog?.description}</p>
            </div>
            <div className="flex justify-start px-6 gap-5 items-center py-5">
              <h2 className="font-bold text-[#1A093F] font-rubik text-sm capitalize">
                by moj ke saudagar
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Articles;

import React, { useState } from "react";
import { IconQuote, IconStarFilled } from "@tabler/icons-react";
import Heading from "./Heading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import LazyImage from "./LazyImage";
const Testimonial = ({ testimonialData }) => {
  const [currentInd, setCurrentInd] = useState(0);
  const testimonialLength = testimonialData.length;
  const certificateSetting = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentInd(current),
    customPaging: (i) => (
      <div className={`ind ${currentInd === i ? "active" : ""}`}></div>
    ),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className="mx-10 md:mx-16 mt-20">
      <div className="flex gap-4 items-center justify-center">
        <Heading heading={"testimonial"} />
      </div>
      <div className="capitalize text-center text-4xl text-[#1A093F] mt-2 font-bold w-full">
        regards from travelers
      </div>

      <div>
        <Slider className="md:mx-auto py-20" {...certificateSetting}>
          {testimonialData.map((testimonial, index) => (
            <div key={index} className="lg:pr-10">
              <div className="group px-5 py-5 w-full bg-[#F8F8F8] h-72 relative flex flex-col items-center rounded-xl cursor-pointer ">
                <p className="text-base leading-normal text-center h-64 overflow-y-auto hidden-scroll">
                  {testimonial?.description}
                </p>
                <div className="flex items-center justify-between w-full mt-4">
                  <div>
                    <div className="flex gap-1 text-[#FD6469] text-[15px] ">
                      {[1, 2, 3, 4, 5].map((item, index) => (
                        <p key={index}>●</p>
                      ))}
                    </div>
                    <p className="capitalize text-xs font-semibold font-rubik">
                      moj ke saudagar
                    </p>
                  </div>
                  <div>
                    <IconQuote className="text-[#FD6469]" />
                  </div>
                  <div className="">
                    <p className="capitalize text-[15px] font-rubik">
                      {testimonial?.createdAt &&
                        moment(testimonial?.createdAt).format("MM-DD-YYYY")}
                    </p>
                    <p className="text-gray-500 capitalize text-xs font-rubik">
                      {testimonial?.createdAt &&
                        moment(testimonial?.createdAt).format("h:mm")}
                    </p>
                  </div>
                </div>
                <div className="text-white  absolute -bottom-6">
                  <div className="w-0 h-0 rounded border-l-[25px] border-l-transparent border-t-[30px] border-t-[#F8F8F8] border-r-[25px] border-r-transparent"></div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center mt-10">
                <LazyImage
                  src={testimonial?.user_img}
                  alt="profile pictre"
                  className="w-12 h-12 rounded-full"
                />
                <p className="text-base font-semibold leading-4 my-2 text-gray-800 capitalize">
                  {testimonial?.user_name}
                </p>
                <div className="flex gap-2 justify-center">
                  {testimonial?.review &&
                    new Array(testimonial?.review > 1 ? testimonial?.review : 1)
                      .fill()
                      .map((item, index) => (
                        <IconStarFilled
                          key={index}
                          className="h-3 w-3 text-orange-400"
                        />
                      ))}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ChevronRightIcon className="h-5 w-5 text-black" />
    </div>
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ChevronLeftIcon className="h-5 w-5 text-black" />
    </div>
  );
}
export default Testimonial;

import React, { useEffect, useState } from "react";
import TourPlan from "./TourPlan";
import Gallery from "./Gallery";
import Reviews from "./Reviews";
import { ContextStore } from "../../context/WebContext";
import Information from "./Information";
import {
  DestinationAPI,
  FavouriteDestinationAPI,
  TourListAPI,
} from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
const PackageDetails = () => {
  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-Y599C6263F");
  }, []);

  const { bookType, setLoading } = ContextStore();
  const location = useLocation();
  const [packageDetail, setPackageDetail] = useState({});
  const packageId = location?.state?.id;
  const packageName = location.pathname.split("/")[2];
  let apiType = location?.state?.type;
  const navigate = useNavigate();
  let ignore = false;
  const getPackageDetails = async () => {
    setLoading(true);
    await fetch(
      `${
        (apiType === "fav" && FavouriteDestinationAPI) ||
        (apiType === "top" && DestinationAPI) ||
        (apiType === "des" && TourListAPI)
      }/get-by-id`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...(packageId ? { _id: packageId } : { packageName: packageName }),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setPackageDetail(data?.data);
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      location.pathname.includes("favourite-destination")
        ? (apiType = "fav")
        : (apiType = "des");
      if ((packageId || packageName) && apiType) {
        getPackageDetails();
      } else {
        navigate("/tour-list");
      }
    }
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{packageDetail?.information?.destination_name}</title>
        </Helmet>
      </HelmetProvider>
      {bookType === "information" && <Information content={packageDetail} />}
      {bookType === "tour_plan" && <TourPlan content={packageDetail} />}
      {bookType === "gallery" && <Gallery content={packageDetail} />}
      {bookType === "reviews" && <Reviews content={packageDetail} />}
    </div>
  );
};

export default PackageDetails;

import React, { useEffect, useState } from "react";
import AboutHero from "../../Components/AboutHero";
import WhatSay from "../../Components/WhatSay";
import Destination from "./Destination";
import {
  AboutPageAPI,
  DestinationAPI,
  TourListAPI,
  TourListPageAPI,
} from "../../API";
import { ContextStore } from "../../context/WebContext";
import WebLoading from "../../Components/WebLoading";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TourListBanner from "../../images/tour-list-banner.jpg";
const TourList = () => {

  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-Y599C6263F`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-Y599C6263F');

  }, []);
  
  const { setLoading, loading } = ContextStore();
  let ignore = false;
  const [tourListData, setTourListData] = useState([]);
  const [tourListPageContents, setTourListPageContents] = useState({});

  const getTourList = async () => {
    setLoading(true);
    await fetch(TourListAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setTourListData(data?.data);
        }
      });
  };
  const getTourListPage = async () => {
    setLoading(true);
    await fetch(TourListPageAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data?.status === "success") {
          setTourListPageContents(data?.data);
        }
      });
  };
  useEffect(() => {
    if (!ignore) {
      getTourListPage();
      getTourList();
    }

    return () => {
      ignore = true;
    };
  }, []);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <title>
            Tour List - Moj ke Saudagar: Itineraries & Pricing for Your Next
            Adventure
          </title> 
          <meta
            name="description"
            content="Explore the Tour List at Moj ke Saudagar to find detailed itineraries and pricing for our domestic and international travel packages. Discover the perfect tour that fits your needs and budget."
          />
          <meta
            name="keywords"
            content="tour list, travel packages, itineraries, package pricing, domestic tours, international tours, Moj ke Saudagar"
          />
          <meta name="author" content="Moj ke Saudagar" />
          
          <link rel="canonical" href="https://mojkesaudagar.com/tour-list"></link>
        </Helmet>
      </HelmetProvider>
      {loading ? (
        <WebLoading />
      ) : (
        <>
          <AboutHero
            pageContent={tourListPageContents}
            imgSrc={TourListBanner}
          />
          <Destination tourListData={tourListData} />
          <WhatSay />
        </>
      )}
    </div>
  );
};

export default TourList;

import React from "react";
import LoveEmoju from "../images/love-emoji.png";
import aboutHero from "../images/banner_img.png";
import emoji1 from "../images/emoji1.png";
import emoji2 from "../images/emoji2.png";
import emoji3 from "../images/emoji3.png";
import emoji4 from "../images/emoji4.png";
import Heading from "./Heading";
import { useLocation } from "react-router-dom";
import LazyImage from "./LazyImage";
const AboutHero = ({ imgSrc, pageContent }) => {
  const location = useLocation();
  const isSingleBlog = location?.pathname?.includes("blog-detail");
  return (
    <div
      className=" bg-gradient-to-b rounded-lg 
    from-white to-red-50"
    >
      <section className="text-gray-600 body-font">
        <div className="px-5 md:px-10 lg:px-20 mx-auto flex  py-10 md:flex-row flex-col items-center">
          <div className="order-2 md:order-1 lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mt-10 md:mb-0 items-center text-center gap-5">
            <div className="flex gap-4 items-center xl:text-start">
              <Heading
                heading={isSingleBlog ? "Blog Details" : pageContent?.title}
              />
            </div>
            <div className="w-full flex justify-center md:justify-start items-center gap-3">
              {isSingleBlog ? (
                <h1 className="font-poppins text-2xl lg:text-3xl xl:text-[48px] leading-snug font-bold text-[#1A093F] capitalize 2xl:text-5xl flex flex-col gap-1 lg:gap-3 xl:gap-5">
                  <span>Dream</span> <span>Destinations..... ✈️</span>
                </h1>
              ) : (
                <h1 className="font-poppins text-3xl md:text-4xl xl:text-[48px] leading-snug font-bold text-[#1A093F] capitalize 2xl:text-5xl flex gap-5">
                  {pageContent?.tag_line}
                </h1>
              )}
              {!isSingleBlog && <img src={LoveEmoju} className="h-12 w-12" />}
            </div>
            {isSingleBlog && (
              <p className="text-justify capitalize text-sm text-gray-500 font-normal w-full lg:text-lg xl:w-3/4">
                Discover your dream places where reality meets imagination, each
                destination a canvas of wonder and adventure waiting to be
                explored. Journey beyond boundaries and embrace the magic of
                travel, one dream place at a time.
              </p>
            )}
            <div>
              {!isSingleBlog && (
                <p className="capitalize text-sm text-gray-500 font-normal w-full lg:text-lg xl:w-3/4 text-justify">
                  {pageContent?.description}
                </p>
              )}
            </div>
          </div>
          <div className="relative order-1 md:order-2 xl:max-w-lg xl:w-full md:w-1/2 w-5/6">
            <LazyImage
              className="object-cover object-center rounded-tl-[100px] rounded-tr-[200px] rounded-br-[200px] border-4 border-[#FD6469]"
              alt="hero"
              src={imgSrc}
            />
            <div className="bg-white absolute -bottom-5 -left-10 md:-left-10 md:-bottom-6 lg:-bottom-8 lg:-left-24 w-3/5 md:w-2/3 py-2 md:py-3 lg:py-4 rounded-xl border-2 border-gray-300 space-y-2">
              <p className="capitalize font-semibold text-black text-center text-sm lg:text-[16px]">
                how your experience ?
              </p>
              <div className="flex gap-3 md:gap-5 lg:gap-8  justify-center">
                <img
                  src={emoji1}
                  className="w-6 h-6 md:w-8 md:h-8 lg:h-10 lg:w-10 cursor-pointer"
                />
                <img
                  src={emoji2}
                  className="w-6 h-6 md:w-8 md:h-8 lg:h-10 lg:w-10 cursor-pointer"
                />
                <img
                  src={emoji3}
                  className="w-6 h-6 md:w-8 md:h-8 lg:h-10 lg:w-10 cursor-pointer"
                />
                <img
                  src={emoji4}
                  className="w-6 h-6 md:w-8 md:h-8 lg:h-10 lg:w-10 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHero;
